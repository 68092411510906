import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Button } from 'react-bootstrap';
import FilterIcon from '../../../assets/icons/filter.svg';
import RefreshIcon from '../../../assets/icons/arrow-clockwise.svg';
import CustomDataGrid from '../../../components/General/CustomDataGrid';
import TransactionDetailsModal from './TransactionDetailsModal';
import FiltersSidebar from './FiltersSidebar';
import axios from 'axios';
import moment from 'moment';
const Transactions = () => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [isFiltersSidebarOpen, setIsFiltersSidebarOpen] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [transaction, setTransaction] = useState([]);

  const columns = [
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'type', headerName: 'Type', width: 150 },
    { field: 'posting', headerName: 'Posting', width: 150 },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'professional', headerName: 'Professional', width: 150 },
    { field: 'payment_method', headerName: 'Payment Method', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
    { field: 'amount', headerName: 'Amount', width: 50 },
  ];


  const getTransaction = (page, limit) => {
    let endpoint = `https://api.mddentalstaffing.com/api/v1/owner/transactions?page=${page}&limit=${limit}`;
    axios.get(endpoint, {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }).then((response) => {
      setTransaction(response.data);
    }).catch((e) => console.log(e));
  };


  const rows = transaction?.data?.map((item) => {


    let posting_type = item.posting.posting_type.charAt(0).toUpperCase() + item.posting.posting_type.slice(1);
    let payment_method = item.payment_method.toUpperCase();
    return {
      id: item.id,
      date: moment(item.created_at).utc().format('MM/DD/YY hh:mm A'),
      type: posting_type,
      posting: item.posting.title,
      location: item.posting.post_location.place_name,
      professional: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <img
            src={
              item.candidate.avatar
                ? `https://api.mddentalstaffing.com/api/v1/assets/${item.candidate.avatar}`
                : item.candidate.profile_photo_path ? item.candidate.profile_photo_path : 'https://via.placeholder.com/150'
            }
            alt=''
            style={{
              width: 30,
              height: 30,
              borderRadius: '50%',
            }}
          />
          <span
            style={{
              color: '#2561B0',
              fontWeight: 400,
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {item.candidate.first_name} {item.candidate.last_name}
          </span>
        </div>
      ),
      payment_method: payment_method,
      status: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <span>{item.status}</span>
          <span
            style={{
              color: '#2561B0',
              fontWeight: 400,
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedItem(item);
              setDetailsModal(true);
            }}
          >
            Details
          </span>
        </div>
      ),
      amount: `$${item.amount / 100}`,
    };
  });


  useEffect(() => {
    getTransaction(1, 10);
  }, []);

  return (
    <Layout
      items={[
        {
          name: 'Transactions',
          link: '/owner/transactions',
        },
      ]}
      basicHeader={{
        title: 'Transactions',
        description: 'All Payments / Transactions',
      }}
    >
      <div
        className='d-flex justify-content-end'
        style={{
          backgroundColor: '#F5F5F5',
          padding: '12px 20px',
          borderBottom: '1px solid #D9D9D9',
          gap: 20,
        }}
      >
        <Button
          style={{
            border: '1px solid #2561B0',
            color: '#595959',
            backgroundColor: '#2561B0',
          }}
          onClick={() => setIsFiltersSidebarOpen(true)}
        >
          <img src={FilterIcon} alt='' />
          <span
            style={{
              marginLeft: 10,
              color: '#fff',
            }}
          >
            Filters
          </span>
        </Button>
        <Button
          style={{
            border: '1px solid #2561B0',
            color: '#595959',
            backgroundColor: '#fff',
          }}
          onClick={() => getTransaction(1, 10)}
        >
          <img src={RefreshIcon} alt='' />
          <span
            style={{
              marginLeft: 10,
              color: '#2561B0',
            }}
          >
            Reset filters
          </span>
        </Button>
      </div>


      {transaction?.data && (
        <CustomDataGrid
          columns={columns}
          rows={rows}
          paging={transaction.paging}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          fetchData={() => {
            getTransaction(1, 10);
          }}
        />
      )}


      {detailsModal && selectedItem && (
        <TransactionDetailsModal
          open={detailsModal}
          handleClose={() => setDetailsModal(false)}
          selectedItem={selectedItem}
          fetchData={() => {
            getTransaction(1, 10);
          }}
        />
      )}

      {isFiltersSidebarOpen && (
        <FiltersSidebar
          isSidebarOpen={isFiltersSidebarOpen}
          setIsSidebarOpen={setIsFiltersSidebarOpen}
          setTransaction={setTransaction}
        />
      )}
    </Layout>
  );
};

export default Transactions;
