import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { selectUser } from "./redux/slices/userSlice";


import {
  setNotification,
  addNotification,
  getNotificationsStatus,
  fetchNotifications,
  selectAllNotifications
} from './redux/slices/notificationSlice';

import { setAuthToken, setIsloggedin } from './redux/slices/authSlice';
import { selectAuthToken, selectIsloggedin } from "./redux/slices/authSlice";

import Pusher from 'pusher-js';


import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setUserTimeZoneGlobally } from "./utils/helper";
import moment from 'moment-timezone';

import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import ForgotPassPage from "./pages/ForgotPassPage";
import PracticeOwnerReg from "./pages/Registration/PracticeOwnerReg";
import ProfessionalReg from "./pages/Registration/ProfessionalReg";
import Congratulations from "./pages/Congratulations";
import NotFound from "./pages/NotFound";
import AccountType from "./components/Login/AccountType";
import PaymentMethod from "./components/PracticeOwnerReg/PaymentMethod";
import "./App.css";
import RegAgreement from "./pages/Registration/RegAgreement";

import TemporaryJobs from "./pages/Owner/Postings/TemporaryJobs";
import CreatePostingsTemporary from "./pages/Owner/Postings/CreatePostingTemporary";
import CreatePostingPerm from "./pages/Owner/Postings/CreatePostingPerm";
import ViewPostings from "./pages/Owner/Postings/TemporaryJobs/ViewPostings";
import PermanentJobs from "./pages/Owner/Postings/PermanentJobs";
import PostingApplicants from "./pages/Owner/Postings/TemporaryJobs/PostingApplicants";
import JobInterviewsGrid from "./pages/Owner/Postings/JobInterviewsGrid";
import PostingApplicantPermanent from "./pages/Owner/Postings/PermanentJobs/PostingApplicantPermanent";
import PostingApplicantProposal from "./pages/Owner/Postings/PermanentJobs/PostingApplicantProposal";
import TemporaryPostingApplicantProposal from "./pages/Owner/Postings/TemporaryJobs/TemporaryPostingApplicantProposal";

import ProfessionalTemporaryJobs from "./pages/Professional/Postings/TemporaryJobs";
import ProfessionalPermanentJobs from "./pages/Professional/Postings/PermanentJobs";
import TemporaryJobProposal from "./pages/Professional/Postings/TemporaryJobs/TemporaryJobProposal";
import PermanentJobProposal from "./pages/Professional/Postings/PermanentJobs/PermanentJobProposal";
import JobCalendar from "./pages/Professional/Postings/TemporaryJobs/JobCalendar";
import JobDetails from "./pages/Professional/Postings/JobDetails";
import MainCalendar from "./pages/Professional/Postings/MainCalendar";
import PermanentJobInterviewsProfessional from "./pages/Professional/Postings/PermanentJobs/Interviews";
import CertificatesMain from "./pages/Professional/Profile/Certificates/CertificatesMain";
import AccountProfile from "./pages/Professional/Profile/AccountProfile";
import Specialities from "./pages/Professional/Profile/Specialties";
import EditQuestionnaireProfile from "./pages/Professional/Profile/Specialties/EditQuestionnaire";
import VaccinesMain from "./pages/Professional/Profile/Vaccines";
import Offices from "./pages/Professional/Offices";
import JobHistory from "./pages/Professional/JobHistory";
import ProfessionalCurrentlyBooked from "./pages/Professional/BookedPostings/CurrentlyBooked";
import ProfessionalPreviouslyBooked from "./pages/Professional/BookedPostings/PreviouslyBooked";
import SkillsAndExperience from "./pages/Professional/Profile/SkillsAndExperience";


import ScheduleJobInterview from "./pages/Owner/Postings/PermanentJobs/ScheduleJobInterview";
import PostingApplicantInterviews from "./pages/Owner/Postings/PermanentJobs/InterviewDetails/PostingApplicantInterviews";
import OwnerProfile from "./pages/Owner/MyAccount/OwnerProfile";
import Billing from "./pages/Owner/MyAccount/Billing";
import OwnerLocations from "./pages/Owner/MyAccount/OwnerLocations";
import EditOwnerLocation from "./pages/Owner/MyAccount/EditOwnerLocation";
import AddOwnerLocation from "./pages/Owner/MyAccount/AddOwnerLocation";
import OwnerJobCalendar from "./pages/Owner/Postings/TemporaryJobs/JobCalendar";
import CurrentlyBooked from "./pages/Owner/BookedPostings/CurrentlyBooked";
import PreviouslyBooked from "./pages/Owner/BookedPostings/PreviouslyBooked";
import Applicants from "./pages/Owner/Applicants";
import Transactions from "./pages/Owner/Transactions";
import Subscription from "./pages/Owner/Subscription";
import OwnerJobHistory from "./pages/Owner/JobHistory";
import OwnerMessages from "./pages/Owner/Messenger";
import ProfessionalMessages from "./pages/Professional/Messenger";
import ReviewManagement from "./pages/Owner/BookedPostings/CurrentlyBooked/ReviewManagement";
import InterviewCalendar from "./pages/Owner/Postings/PermanentJobs/InterviewCalendar";
import AmbassadorProgram from "./pages/Owner/AmbassadorProgram";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';
import NotificationSound from "./notification-sound.mp3";

const initPusher = () => {
  const pusher = new Pusher('9327abb9d05e103ead60', {
    cluster: 'mt1',
  });
  return pusher;
};

const useChannel = (channelName) => {
  const pusher = initPusher();
  const channel = useMemo(() => {
    if (!channelName) return null;
    if (!pusher.channel(channelName)) {
      return pusher.subscribe(channelName);
    }
    return pusher.channel(channelName);
  }, [channelName]);

  return channel;
};

function App() {
  const redUser = useSelector(selectUser);
  const authToken = useSelector(selectAuthToken);
  const loading = useSelector((state) => state.loading.isLoading);
  const isloggedin = useSelector(selectIsloggedin);
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const notificationsStatus = useSelector(getNotificationsStatus);
  const notifications = useSelector(selectAllNotifications);
  const audioPlayer = useRef(null);
  // const channel = useChannel(currentUser?.socket_channel);

  const playAudio = () => {
    audioPlayer.current.play();
  };

  useEffect(() => {
    if (redUser.is_profile_completed) {
      // console.log('Bind');
      const pusher = new Pusher('9327abb9d05e103ead60', {
        cluster: 'mt1',
      });
      const channel = pusher.channel(currentUser?.socket_channel) || pusher.subscribe(currentUser?.socket_channel);
      channel.bind('notification', function (data) {
        dispatch(addNotification(data));
        playAudio();
      });
      return (() => {
        pusher.unsubscribe(currentUser?.socket_channel)
      })
    } else {
      // console.log('Wakeel');
    }
  }, [redUser]);

  useEffect(() => {
    if (notificationsStatus === 'idle' && redUser.is_profile_completed) {
      dispatch(fetchNotifications(redUser.authToken));
    } else {
      console.log('isloggedin', isloggedin, redUser.is_profile_completed);
    }
  }, [redUser]);

  // useEffect(() => {
  //   if (notificationsStatus === 'idle' && isloggedin) {
  //     dispatch(fetchNotifications(redUser.authToken));
  //   } else {
  //     console.log('isloggedin', isloggedin);
  //   }
  // }, [isloggedin]);


  // useEffect(() => {
  //   if (redUser.is_profile_completed) {
  //     channel.bind('notification', function (data) {
  //       dispatch(addNotification(data));
  //     });
  //   } else {
  //     console.log('No init');
  //   }
  // }, []);

  setUserTimeZoneGlobally(redUser.user_time_zone);

  const theme = createTheme({
    palette: {
      sunset: {
        main: "#FA5A16",
      },
      error: {
        main: "#FA5A16",
      },
      success: {
        main: "#4CAF50",
      },
      lightblue: {
        main: "#75B0FA",
      },
      greyFF: {
        main: "#FFFFFF",
      },
      sYellow: {
        main: "#FFCF33",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "&.Mui-error .MuiInputBase-input": {
              borderColor: "#FA5A16", // Custom error border color
            },

          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "#FA5A16", // Apply the custom color to asterisk
          },
        },
      },
    },
  });


  if (loading.isLoading) {
    return (
      <div
        style={{
          fontSize: 20,
        }}
      >
        Loading...
      </div>
    );
  }

  if (redUser.is_profile_completed) {
    if (redUser.role_type === "owner") {
      return (
        <ThemeProvider theme={theme}>
          <RouterProvider
            router={createBrowserRouter(
              createRoutesFromElements(
                <Route>
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/owner/account/profile"
                    element={<OwnerProfile />}
                  />
                  <Route
                    path="/owner/account/billing"
                    element={<Billing />}
                  />
                  <Route
                    path="/registration/completed"
                    element={<Congratulations />}
                  />
                  <Route index element={<OwnerJobCalendar />} />
                  <Route
                    path="/owner/dashboard"
                    element={<OwnerJobCalendar />}
                  />
                  <Route
                    path="/owner/postings/create/temporary"
                    element={<CreatePostingsTemporary />}
                  />
                  <Route
                    path="/owner/postings/edit/temporary/:id"
                    element={<CreatePostingsTemporary />}
                  />
                  <Route
                    path="/owner/postings/view/temporary/:id"
                    element={<CreatePostingsTemporary />}
                  />
                  <Route
                    path="/owner/postings/clone/temporary/:id"
                    element={<CreatePostingsTemporary />}
                  />
                  <Route
                    path="/owner/postings/create/permanent"
                    element={<CreatePostingPerm />}
                  />
                  <Route
                    path="/owner/postings/edit/permanent/:id"
                    element={<CreatePostingPerm />}
                  />
                  <Route
                    path="/owner/postings/view/permanent/:id"
                    element={<CreatePostingPerm />}
                  />
                  <Route
                    path="/owner/postings/clone/permanent/:id"
                    element={<CreatePostingPerm />}
                  />
                  <Route
                    path="/owner/postings/permanent/applicants/:id"
                    element={<PostingApplicantPermanent />}
                  />
                  <Route
                    path="/owner/postings/permanent/proposal/applicant/:applicantId/post/:postid"
                    element={<PostingApplicantProposal />}
                  />

                  <Route
                    path="/owner/postings/temporary/proposal/applicant/:applicantId/post/:postid/schedule/:scheduleId"
                    element={<TemporaryPostingApplicantProposal />}
                  />

                  <Route
                    path="/owner/postings/temporary"
                    element={<TemporaryJobs />}
                  />
                  <Route
                    path="/owner/postings/temporary/:id"
                    element={<ViewPostings />}
                  />
                  <Route
                    path="/owner/postings/temporary/:postingId/calendar"
                    element={<OwnerJobCalendar />}
                  />
                  <Route
                    path="/owner/postings/temporary/:id/applicants/:scheduleId"
                    element={<PostingApplicants />}
                  />

                  <Route
                    path="/owner/postings/temporary/interviews/applicant/:id/post/:postingId/schedule/:scheduleId"
                    element={<JobInterviewsGrid />}
                  />


                  <Route
                    path="/owner/postings/permanent"
                    element={<PermanentJobs />}
                  />
                  <Route
                    path="/owner/postings/permanent/interview-calendar/:id"
                    element={<InterviewCalendar />}
                  />
                  <Route
                    path="/owner/postings/permanent/:postingId/applicants/:applicantId/schedule"
                    element={<ScheduleJobInterview />}
                  />

                  <Route
                    path="/owner/postings/permanent/applicant/interview/details/:postingId/applicants/:applicantId/schedule"
                    element={<PostingApplicantInterviews />}
                  />

                  <Route
                    path="/owner/account/locations"
                    element={<OwnerLocations />}
                  />

                  <Route
                    path="/owner/account/locations/add"
                    element={<AddOwnerLocation />}
                  />
                  <Route
                    path="/owner/account/locations/:id"
                    element={<EditOwnerLocation />}
                  />

                  <Route
                    path="/owner/booked/currently/:postingId/review/:id"
                    element={<ReviewManagement />}
                  />
                  <Route
                    path="/owner/booked/previously"
                    element={<PreviouslyBooked />}
                  />


                  <Route
                    path="/owner/booked/currently"
                    element={<CurrentlyBooked />}
                  />

                  <Route path="/owner/applicants" element={<Applicants />} />


                  <Route
                    path="/owner/subscription"
                    element={<Subscription />}
                  />

                  <Route
                    path="/owner/transactions"
                    element={<Transactions />}
                  />

                  <Route
                    path="/owner/jobHistory"
                    element={<OwnerJobHistory />}
                  />
                  <Route
                    path="/owner/postings/calendar"
                    element={<OwnerJobCalendar />}
                  />

                  <Route
                    path="/owner/special/offer"
                    element={<AmbassadorProgram />}
                  />

                  <Route path="/*" element={<NotFound />} />
                  <Route path="/messages" element={<OwnerMessages />} />
                </Route>
              )
            )}
          />
          <audio ref={audioPlayer} src={NotificationSound} />
        </ThemeProvider>
      );
    } else if (redUser.role_type === "professional") {
      return (
        <ThemeProvider theme={theme}>
          <RouterProvider
            router={createBrowserRouter(
              createRoutesFromElements(
                <Route>
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/registration/completed"
                    element={<Congratulations />}
                  />
                  <Route index element={<MainCalendar />} />
                  <Route
                    path="/professional/dashboard"
                    element={<MainCalendar />}
                  />
                  <Route path="/*" element={<NotFound />} />
                  <Route
                    path="/professional/jobs/temporary/"
                    element={<ProfessionalTemporaryJobs />}
                  />

                  <Route
                    path="/professional/jobs/temporary/proposal/post/:id"
                    element={<TemporaryJobProposal />}
                  />
                  <Route
                    path="/professional/jobs/permanent/proposal/post/:id"
                    element={<PermanentJobProposal />}
                  />

                  <Route
                    path="/professional/jobs/calendar"
                    element={<MainCalendar />}
                  />
                  <Route
                    path="/professional/jobs/temporary/:id"
                    element={<JobDetails />}
                  />
                  <Route
                    path="/professional/jobs/temporary/:id/calendar"
                    element={<JobCalendar />}
                  />
                  <Route
                    path="/professional/jobs/permanent/"
                    element={<ProfessionalPermanentJobs />}
                  />
                  <Route
                    path="/professional/jobs/permanent/:id"
                    element={<JobDetails />}
                  />
                  <Route
                    path="/professional/jobs/permanent/:id/interviews"
                    element={<PermanentJobInterviewsProfessional />}
                  />
                  <Route
                    path="/professional/account/certificates"
                    element={<CertificatesMain />}
                  />
                  <Route
                    path="/professional/account/profile"
                    element={<AccountProfile />}
                  />
                  <Route
                    path="/professional/account/skills"
                    element={<SkillsAndExperience />}
                  />
                  <Route
                    path="/professional/account/specialties"
                    element={<Specialities />}
                  />
                  <Route
                    path="/professional/account/specialties/edit"
                    element={<EditQuestionnaireProfile />}
                  />
                  <Route
                    path="/professional/account/vaccines"
                    element={<VaccinesMain />}
                  />
                  <Route path="/professional/offices" element={<Offices />} />
                  <Route
                    path="/professional/jobHistory"
                    element={<JobHistory />}
                  />

                  {/* <Route
                    path="/professional/booked/previously"
                    element={<ProfessionalPreviouslyBooked />}
                  />

                  <Route
                    path="/professional/booked/currently"
                    element={<ProfessionalCurrentlyBooked />}
                  /> */}

                  <Route
                    path="/professional/special/offer"
                    element={<AmbassadorProgram />}
                  />

                  <Route path="/messages" element={<ProfessionalMessages />} />
                </Route>
              )
            )}
          />
          <audio ref={audioPlayer} src={NotificationSound} />
        </ThemeProvider>
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider
        router={createBrowserRouter(
          createRoutesFromElements(
            <Route>
              <Route index element={<LoginPage />} />
              <Route
                path="/login"
                element={
                  redUser.role_type ? <Navigate to="/" /> : <LoginPage />
                }
              />
              <Route
                path="/signup"
                element={
                  redUser.role_type ? <Navigate to="/" /> : <SignUpPage />
                }
              />
              <Route path="/recover-password" element={<ForgotPassPage />} />
              <Route
                path="/registration/agreement"
                element={<RegAgreement />}
              />
              <Route
                path="/registration/completed"
                element={<Congratulations />}
              />
              <Route
                path="/registration/owner"
                element={<PracticeOwnerReg />}
              />
              <Route
                path="/registration/professional"
                element={<ProfessionalReg />}
              />
              <Route path="/selectRole" element={<AccountType />} />

              <Route path="/payment-method" element={<PaymentMethod />} />

              <Route path="/*" element={<NotFound />} />
              <Route
                path="/owner/postings/create/temporary"
                element={<CreatePostingsTemporary />}
              />
              <Route
                path="/owner/postings/create/permanent"
                element={<CreatePostingPerm />}
              />
            </Route>
          )
        )}
      />
    </ThemeProvider>
  );
}

export default App;
