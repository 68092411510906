import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import LogoutIcon from '@mui/icons-material/Logout';
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ListItemButton from '@mui/material/ListItemButton';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import {
  Button,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ProCheckInDialog from './ProCheckInDialog';
import CheckoutDialog from './CheckoutDialog';

import SuccessModal from '../General/SuccessModal';
import ErrorModal from '../General/ErrorModal';

// import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import axios from 'axios';
import { useCallback } from 'react';


import {
  setIscheckedIn,
  getCheckedInStatus,
  fetchIscheckedIn,
  selectIscheckedIn
} from '../../redux/slices/checkInSlice';

import { useDispatch, useSelector } from 'react-redux';


export default function ProfessionalDrawer({ isSidebarOpen, isMobile }) {

  const dispatch = useDispatch();
  const fetchIscheckedInStatus = useSelector(getCheckedInStatus);
  const ischeckedIn = useSelector(selectIscheckedIn);


  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [checkoutDialoag, setCheckoutDialoag] = useState(false);
  const handleOpenCheckoutDialoag = () => {
    setCheckoutDialoag(true);
  };
  const handleCloseCheckoutDialoag = () => {
    setCheckoutDialoag(false);
  };


  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSubListCreateOpen, setIsSubListCreateOpen] = useState(false);
  const [isSubListAccountOpen, setIsSubListAccountOpen] = useState(false);
  const [isSubListBookedOpen, setIsSubListBookedOpen] = useState(false);
  // const [ischeckedIn, setIscheckedIn] = useState(false);


  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [selectedItem, setSelectedItem] = useState(
    pathname === "/professional/jobs/temporary"
      ? 11
      : pathname === "/professional/jobs/permanent"
        ? 12
        : pathname === "/professional/account/profile"
          ? 61
          : pathname === "/professional/account/specialties"
            ? 62
            : pathname === "/professional/account/certificates"
              ? 63
              : pathname === "/professional/account/vaccines"
                ? 64
                : pathname === "/professional/account/skills"
                  ? 65
                  : pathname === "/professional/offices"
                    ? 4
                    : pathname === "/professional/jobHistory"
                      ? 3
                      : pathname === "/professional/specialOffers"
                        ? 8
                        : pathname === "/professional/userManual"
                          ? 7
                          : pathname === '/professional/booked/currently'
                            ? 101
                            : pathname === '/professional/booked/previously'
                              ? 102
                              : null
  );

  const handleSubListCreateToggle = () => {
    setIsSubListAccountOpen(false);
    setIsSubListCreateOpen(!isSubListCreateOpen);
  };

  const handleSubListAccountToggle = () => {
    setIsSubListAccountOpen(!isSubListAccountOpen);
    setIsSubListCreateOpen(false);
  };

  const handleSubListBookedToggle = () => {
    setIsSubListBookedOpen(!isSubListBookedOpen);
    setIsSubListCreateOpen(false);
  };

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };


  // const checkInStatus = () => {
  //   axios.get('https://api.mddentalstaffing.com/api/v1/check-in', {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
  //     },
  //   }).then((res) => {
  //     setIscheckedIn(res.data.data.is_checked_in);
  //   }).catch((e) => {
  //     console.log('error loc=== >', e);
  //   });
  // };


  // const checkOut = () => {
  //   axios.get('https://api.mddentalstaffing.com/api/v1/check-out', {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
  //     },
  //   }).then((res) => {
  //     if (res.checkout) {
  //       dispatch(setIscheckedIn(false));
  //     }
  //   }).catch((e) => {
  //     console.log('error loc=== >', e);
  //   });
  // };


  useEffect(() => {
    console.log('ischeckedIn === >', ischeckedIn);
  }, [ischeckedIn]);



  useEffect(() => {
    if (fetchIscheckedInStatus === 'idle') {
      dispatch(fetchIscheckedIn());
    }
  }, []);



  useEffect(() => {
    if (selectedItem === 11 || selectedItem === 12) {
      setIsSubListCreateOpen(true);
    } else if (
      selectedItem === 61 ||
      selectedItem === 62 ||
      selectedItem === 63 ||
      selectedItem === 64 ||
      selectedItem === 65
    ) {
      setIsSubListAccountOpen(true);
    } else if (
      selectedItem === 101 ||
      selectedItem === 102
    ) {
      setIsSubListBookedOpen(true);
    } else {
      setIsSubListCreateOpen(false);
      setIsSubListAccountOpen(false);
    }
  }, [selectedItem]);

  const buttonStyle = {
    backgroundColor: isHovered ? "#b28900" : "#FFCF33",
    width: "100%",
    textTransform: "none",
    padding: "10px 0px",
    color: "#262626",
    borderRadius: "4px",
    transition: "background-color 0.3s",
  };

  const gridStyle = {
    width: 265,
    display: isSidebarOpen ? "block" : "none",
    marginTop: 0,
    flexShrink: 0,
    minHeight: isMobile ? "100vh" : "calc(100vh - 65px)",
    boxSizing: "border-box",
    backgroundColor: "#2561B0",
    boxShadow: "none",
  };

  return (
    <div style={gridStyle}>
      <List>
        <ListItem
          sx={{
            py: 1.2,
            borderLeft: isSubListCreateOpen ? "3.5px solid #FFC400" : "initial",
          }}
          onClick={handleSubListCreateToggle}
        >
          <ListItemIcon>
            <WorkOutlineIcon color="greyFF" />
          </ListItemIcon>
          <ListItemText
            sx={{
              color: "#FFFFFF",
              fontSize: "0.5rem",
            }}
            primary="All Jobs"
          />
          {isSubListCreateOpen ? (
            <ExpandLessIcon color="greyFF" />
          ) : (
            <ExpandMoreIcon color="greyFF" />
          )}
        </ListItem>
        <Collapse in={isSubListCreateOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{ backgroundColor: "#296DC4" }}>
            <ListItem
              onSelect={() => handleItemClick(11)}
              onClick={() => {
                handleItemClick(11);
                navigate("/professional/jobs/temporary");
              }}
              sx={{
                backgroundColor: selectedItem === 11 ? "#21589F" : "initial",
                borderRadius: selectedItem === 11 ? "6px" : "initial",
                width: selectedItem === 11 ? "80%" : "initial",
              }}
            >
              <ListItemIcon>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.666 3.41666C16.591 3.41666 17.3327 4.15833 17.3327 5.08333H15.666H2.33268V14.25H15.666H17.3327C17.3327 15.175 16.591 15.9167 15.666 15.9167H2.33268C1.40768 15.9167 0.666016 15.175 0.666016 14.25L0.674349 5.08333C0.674349 4.15833 1.40768 3.41666 2.33268 3.41666H5.66602V1.74999C5.66602 0.824995 6.40768 0.0833282 7.33268 0.0833282H10.666C11.591 0.0833282 12.3327 0.824995 12.3327 1.74999V3.41666H15.666ZM10.666 1.74999V3.41666H7.33268V1.74999H10.666ZM16.0827 8.41666H14.8327V7.16666H18.5827V8.41666H17.3327V12.1667H16.0827V8.41666Z"
                    fill={selectedItem === 11 ? "#FFCF33" : "#1B5198"}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText sx={{ color: "#FFFFFF" }} primary="Temporary Job" />
            </ListItem>
            <ListItem
              onSelect={() => handleItemClick(12)}
              onClick={() => {
                handleItemClick(12);
                navigate("/professional/jobs/permanent");
              }}
              sx={{
                backgroundColor: selectedItem === 12 ? "#21589F" : "initial",
                borderRadius: selectedItem === 12 ? "6px" : "initial",
                width: selectedItem === 12 ? "80%" : "initial",
              }}
            >
              <ListItemIcon>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.3327 5.08334C17.3327 4.15834 16.591 3.41667 15.666 3.41667H12.3327V1.75C12.3327 0.825002 11.591 0.0833359 10.666 0.0833359H7.33268C6.40768 0.0833359 5.66602 0.825002 5.66602 1.75V3.41667H2.33268C1.40768 3.41667 0.674349 4.15834 0.674349 5.08334L0.666016 14.25C0.666016 15.175 1.40768 15.9167 2.33268 15.9167H15.666C16.591 15.9167 17.3327 15.175 17.3327 14.25H15.666H2.33268V5.08334H15.666H17.3327ZM10.666 3.41667V1.75H7.33268V3.41667H10.666ZM17.7493 7.16667H14.8327V12.1667H16.0827V10.5H17.7493C18.416 10.5 18.9993 9.91667 18.9993 9.25V8.41667C18.9993 7.75 18.416 7.16667 17.7493 7.16667ZM16.0827 9.25H17.7493V8.41667H16.0827V9.25Z"
                    fill={selectedItem === 12 ? "#FFCF33" : "#1B5198"}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText sx={{ color: "#FFFFFF" }} primary="Permanent Job" />
            </ListItem>
          </List>
        </Collapse>


        {/* <ListItemButton
          sx={{
            py: 1.2,
            borderLeft: isSubListBookedOpen ? '3.5px solid #FFC400' : 'initial',
          }}
          onClick={handleSubListBookedToggle}
        >
          <ListItemIcon>
            <ChromeReaderModeOutlinedIcon color='greyFF' />
          </ListItemIcon>
          <ListItemText
            sx={{ color: '#FFFFFF', fontSize: '0.5rem' }}
            primary='Booked Postings'
          />
          {isSubListBookedOpen ? (
            <ExpandLessIcon color='greyFF' />
          ) : (
            <ExpandMoreIcon color='greyFF' />
          )}
        </ListItemButton>

        <Collapse in={isSubListBookedOpen} timeout='auto' unmountOnExit>
          <List component='div' sx={{ backgroundColor: '#296DC4' }}>
            <ListItemButton
              onSelect={() => handleItemClick(101)}
              onClick={() => {
                handleItemClick(101);
                navigate('/professional/booked/currently');
                // window.location.reload();
              }}
              sx={{
                backgroundColor: selectedItem === 101 ? '#21589F' : 'initial',
                borderRadius: selectedItem === 101 ? '6px' : 'initial',
                width: selectedItem === 101 ? '80%' : 'initial',
              }}
            >
              <ListItemIcon>
                <svg
                  width='19'
                  height='16'
                  viewBox='0 0 19 16'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M15.666 3.41666C16.591 3.41666 17.3327 4.15833 17.3327 5.08333H15.666H2.33268V14.25H15.666H17.3327C17.3327 15.175 16.591 15.9167 15.666 15.9167H2.33268C1.40768 15.9167 0.666016 15.175 0.666016 14.25L0.674349 5.08333C0.674349 4.15833 1.40768 3.41666 2.33268 3.41666H5.66602V1.74999C5.66602 0.824995 6.40768 0.0833282 7.33268 0.0833282H10.666C11.591 0.0833282 12.3327 0.824995 12.3327 1.74999V3.41666H15.666ZM10.666 1.74999V3.41666H7.33268V1.74999H10.666ZM16.0827 8.41666H14.8327V7.16666H18.5827V8.41666H17.3327V12.1667H16.0827V8.41666Z'
                    fill={selectedItem === 101 ? '#FFCF33' : '#1B5198'}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                sx={{ color: '#FFFFFF' }}
                primary='Currently Booked'
              />
            </ListItemButton>
            <ListItemButton
              onSelect={() => handleItemClick(102)}
              onClick={() => {
                handleItemClick(102);
                navigate('/professional/booked/previously');
                // window.location.reload();
              }}
              sx={{
                backgroundColor: selectedItem === 102 ? '#21589F' : 'initial',
                borderRadius: selectedItem === 102 ? '6px' : 'initial',
                width: selectedItem === 102 ? '80%' : 'initial',
              }}
            >
              <ListItemIcon>
                <svg
                  width='19'
                  height='16'
                  viewBox='0 0 19 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M17.3327 5.08334C17.3327 4.15834 16.591 3.41667 15.666 3.41667H12.3327V1.75C12.3327 0.825002 11.591 0.0833359 10.666 0.0833359H7.33268C6.40768 0.0833359 5.66602 0.825002 5.66602 1.75V3.41667H2.33268C1.40768 3.41667 0.674349 4.15834 0.674349 5.08334L0.666016 14.25C0.666016 15.175 1.40768 15.9167 2.33268 15.9167H15.666C16.591 15.9167 17.3327 15.175 17.3327 14.25H15.666H2.33268V5.08334H15.666H17.3327ZM10.666 3.41667V1.75H7.33268V3.41667H10.666ZM17.7493 7.16667H14.8327V12.1667H16.0827V10.5H17.7493C18.416 10.5 18.9993 9.91667 18.9993 9.25V8.41667C18.9993 7.75 18.416 7.16667 17.7493 7.16667ZM16.0827 9.25H17.7493V8.41667H16.0827V9.25Z'
                    fill={selectedItem === 102 ? '#FFCF33' : '#1B5198'}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                sx={{ color: '#FFFFFF' }}
                primary='Previously Booked'
              />
            </ListItemButton>
          </List>
        </Collapse> */}


        <ListItem
          sx={{
            py: 1.2,
            backgroundColor: selectedItem === 4 ? "#21589F" : "initial",
            borderLeft: selectedItem === 4 ? "3.5px solid #FFC400" : "initial",
          }}
          onClick={() => {
            handleItemClick(4);
            navigate("/professional/offices");
            window.location.reload();
          }}
          onSelect={() => handleItemClick(4)}
        >
          <ListItemIcon>
            <AttachMoneyOutlinedIcon
              color={selectedItem === 4 ? "sYellow" : "greyFF"}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#FFFFFF", fontSize: "0.5rem" }}
            primary="Offices"
          />
        </ListItem>

        <ListItem
          sx={{
            py: 1.2,
            backgroundColor: selectedItem === 3 ? "#21589F" : "initial",
            borderLeft: selectedItem === 3 ? "3.5px solid #FFC400" : "initial",
          }}
          onClick={() => {
            handleItemClick(3);
            navigate("/professional/jobHistory");
          }}
          onSelect={() => handleItemClick(3)}
        >
          <ListItemIcon>
            <HistoryOutlinedIcon
              color={selectedItem === 3 ? "sYellow" : "greyFF"}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#FFFFFF", fontSize: "0.5rem" }}
            primary="Job History"
          />
        </ListItem>

        <ListItem
          sx={{
            py: 1.2,
            borderLeft: isSubListAccountOpen
              ? "3.5px solid #FFC400"
              : "initial",
          }}
          onClick={handleSubListAccountToggle}
        >
          <ListItemIcon>
            <PersonOutlineOutlinedIcon color="greyFF" />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#FFFFFF", fontSize: "0.5rem" }}
            primary="My Account"
          />
          {isSubListAccountOpen ? (
            <ExpandLessIcon color="greyFF" />
          ) : (
            <ExpandMoreIcon color="greyFF" />
          )}
        </ListItem>

        <Collapse in={isSubListAccountOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{ backgroundColor: "#296DC4" }}>
            <ListItem
              onSelect={() => handleItemClick(61)}
              onClick={() => {
                handleItemClick(61);
                navigate("/professional/account/profile");
              }}
              sx={{
                backgroundColor: selectedItem === 61 ? "#21589F" : "initial",
                borderRadius: selectedItem === 61 ? "6px" : "initial",
                width: selectedItem === 61 ? "80%" : "initial",
              }}
            >
              <ListItemIcon>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.666 3.41666C16.591 3.41666 17.3327 4.15833 17.3327 5.08333H15.666H2.33268V14.25H15.666H17.3327C17.3327 15.175 16.591 15.9167 15.666 15.9167H2.33268C1.40768 15.9167 0.666016 15.175 0.666016 14.25L0.674349 5.08333C0.674349 4.15833 1.40768 3.41666 2.33268 3.41666H5.66602V1.74999C5.66602 0.824995 6.40768 0.0833282 7.33268 0.0833282H10.666C11.591 0.0833282 12.3327 0.824995 12.3327 1.74999V3.41666H15.666ZM10.666 1.74999V3.41666H7.33268V1.74999H10.666ZM16.0827 8.41666H14.8327V7.16666H18.5827V8.41666H17.3327V12.1667H16.0827V8.41666Z"
                    fill={selectedItem === 61 ? "#FFCF33" : "#1B5198"}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#FFFFFF" }}
                primary="My Profile / Edit"
              />
            </ListItem>
            <ListItem
              onSelect={() => handleItemClick(62)}
              onClick={() => {
                handleItemClick(62);
                navigate("/professional/account/specialties");
              }}
              sx={{
                backgroundColor: selectedItem === 62 ? "#21589F" : "initial",
                borderRadius: selectedItem === 62 ? "6px" : "initial",
                width: selectedItem === 62 ? "80%" : "initial",
              }}
            >
              <ListItemIcon>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.3327 5.08334C17.3327 4.15834 16.591 3.41667 15.666 3.41667H12.3327V1.75C12.3327 0.825002 11.591 0.0833359 10.666 0.0833359H7.33268C6.40768 0.0833359 5.66602 0.825002 5.66602 1.75V3.41667H2.33268C1.40768 3.41667 0.674349 4.15834 0.674349 5.08334L0.666016 14.25C0.666016 15.175 1.40768 15.9167 2.33268 15.9167H15.666C16.591 15.9167 17.3327 15.175 17.3327 14.25H15.666H2.33268V5.08334H15.666H17.3327ZM10.666 3.41667V1.75H7.33268V3.41667H10.666ZM17.7493 7.16667H14.8327V12.1667H16.0827V10.5H17.7493C18.416 10.5 18.9993 9.91667 18.9993 9.25V8.41667C18.9993 7.75 18.416 7.16667 17.7493 7.16667ZM16.0827 9.25H17.7493V8.41667H16.0827V9.25Z"
                    fill={selectedItem === 62 ? "#FFCF33" : "#1B5198"}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#FFFFFF" }}
                primary="Add/ Edit Specialities"
              />
            </ListItem>
            <ListItem
              onSelect={() => handleItemClick(63)}
              onClick={() => {
                handleItemClick(63);
                navigate("/professional/account/certificates");
              }}
              sx={{
                backgroundColor: selectedItem === 63 ? "#21589F" : "initial",
                borderRadius: selectedItem === 63 ? "6px" : "initial",
                width: selectedItem === 63 ? "80%" : "initial",
              }}
            >
              <ListItemIcon>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.3327 5.08334C17.3327 4.15834 16.591 3.41667 15.666 3.41667H12.3327V1.75C12.3327 0.825002 11.591 0.0833359 10.666 0.0833359H7.33268C6.40768 0.0833359 5.66602 0.825002 5.66602 1.75V3.41667H2.33268C1.40768 3.41667 0.674349 4.15834 0.674349 5.08334L0.666016 14.25C0.666016 15.175 1.40768 15.9167 2.33268 15.9167H15.666C16.591 15.9167 17.3327 15.175 17.3327 14.25H15.666H2.33268V5.08334H15.666H17.3327ZM10.666 3.41667V1.75H7.33268V3.41667H10.666ZM17.7493 7.16667H14.8327V12.1667H16.0827V10.5H17.7493C18.416 10.5 18.9993 9.91667 18.9993 9.25V8.41667C18.9993 7.75 18.416 7.16667 17.7493 7.16667ZM16.0827 9.25H17.7493V8.41667H16.0827V9.25Z"
                    fill={selectedItem === 63 ? "#FFCF33" : "#1B5198"}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#FFFFFF" }}
                primary="Add/ Edit Certificates"
              />
            </ListItem>
            <ListItem
              onSelect={() => handleItemClick(64)}
              onClick={() => {
                handleItemClick(64);
                navigate("/professional/account/vaccines");
              }}
              sx={{
                backgroundColor: selectedItem === 64 ? "#21589F" : "initial",
                borderRadius: selectedItem === 64 ? "6px" : "initial",
                width: selectedItem === 64 ? "80%" : "initial",
              }}
            >
              <ListItemIcon>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.666 3.41666C16.591 3.41666 17.3327 4.15833 17.3327 5.08333H15.666H2.33268V14.25H15.666H17.3327C17.3327 15.175 16.591 15.9167 15.666 15.9167H2.33268C1.40768 15.9167 0.666016 15.175 0.666016 14.25L0.674349 5.08333C0.674349 4.15833 1.40768 3.41666 2.33268 3.41666H5.66602V1.74999C5.66602 0.824995 6.40768 0.0833282 7.33268 0.0833282H10.666C11.591 0.0833282 12.3327 0.824995 12.3327 1.74999V3.41666H15.666ZM10.666 1.74999V3.41666H7.33268V1.74999H10.666ZM16.0827 8.41666H14.8327V7.16666H18.5827V8.41666H17.3327V12.1667H16.0827V8.41666Z"
                    fill={selectedItem === 64 ? "#FFCF33" : "#1B5198"}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#FFFFFF" }}
                primary="Add / Edit Vaccines"
              />
            </ListItem>

            <ListItem
              onSelect={() => handleItemClick(65)}
              onClick={() => {
                handleItemClick(65);
                navigate("/professional/account/skills");
              }}
              sx={{
                backgroundColor: selectedItem === 65 ? "#21589F" : "initial",
                borderRadius: selectedItem === 65 ? "6px" : "initial",
                width: selectedItem === 65 ? "80%" : "initial",
              }}
            >
              <ListItemIcon>
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.3327 5.08334C17.3327 4.15834 16.591 3.41667 15.666 3.41667H12.3327V1.75C12.3327 0.825002 11.591 0.0833359 10.666 0.0833359H7.33268C6.40768 0.0833359 5.66602 0.825002 5.66602 1.75V3.41667H2.33268C1.40768 3.41667 0.674349 4.15834 0.674349 5.08334L0.666016 14.25C0.666016 15.175 1.40768 15.9167 2.33268 15.9167H15.666C16.591 15.9167 17.3327 15.175 17.3327 14.25H15.666H2.33268V5.08334H15.666H17.3327ZM10.666 3.41667V1.75H7.33268V3.41667H10.666ZM17.7493 7.16667H14.8327V12.1667H16.0827V10.5H17.7493C18.416 10.5 18.9993 9.91667 18.9993 9.25V8.41667C18.9993 7.75 18.416 7.16667 17.7493 7.16667ZM16.0827 9.25H17.7493V8.41667H16.0827V9.25Z"
                    fill={selectedItem === 65 ? "#FFCF33" : "#1B5198"}
                  />
                </svg>
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#FFFFFF" }}
                primary="Skills And Experience"
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          sx={{
            py: 1.2,
            backgroundColor: selectedItem === 8 ? "#21589F" : "initial",
            borderLeft: selectedItem === 8 ? "3.5px solid #FFC400" : "initial",
          }}
          onClick={() => {
            navigate('/professional/special/offer');
            handleItemClick(8)
          }}
          onSelect={() => handleItemClick(8)}
        >
          <ListItemIcon>
            <StarsOutlinedIcon
              color={selectedItem === 8 ? "sYellow" : "greyFF"}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#FFFFFF", fontSize: "0.5rem" }}
            primary="Special Offers"
          />
        </ListItem>

        <ListItem
          sx={{
            py: 1.2,
            backgroundColor: selectedItem === 7 ? "#21589F" : "initial",
            borderLeft: selectedItem === 7 ? "3.5px solid #FFC400" : "initial",
          }}
          onClick={() => handleItemClick(7)}
          onSelect={() => handleItemClick(7)}
        >
          <ListItemIcon>
            <HelpOutlineOutlinedIcon
              color={selectedItem === 7 ? "sYellow" : "greyFF"}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#FFFFFF", fontSize: "0.5rem" }}
            primary="User's Manual"
          />
        </ListItem>

        <Divider
          variant="middle"
          sx={{
            border: "1.5px solid #194378",
            my: 2,
          }}
        />

        {ischeckedIn ? (
          <>
            <ListItem
              sx={{ py: 1.2 }}
              onSelect={() => handleItemClick(9)}
              onClick={() => {
                handleItemClick(9);
                handleOpenCheckoutDialoag();
              }}
            >
              <Button
                variant="text"
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <LogoutIcon sx={{ mx: 0.5 }} /> Check out
              </Button>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem
              sx={{ py: 1.2 }}
              onSelect={() => handleItemClick(9)}
              onClick={() => {
                handleItemClick(9);
                handleClickOpen();
              }}
            >
              <Button
                variant="text"
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <QrCodeScannerRoundedIcon sx={{ mx: 0.5 }} /> Check In
              </Button>
            </ListItem>
          </>
        )}


      </List>
      <p
        style={{
          color: "#FFFFFF",
          fontSize: "0.9rem",
          textAlign: "center",
        }}
        className="mx-4 my-3"
      >
        Please scan QR-Code that was generated for the practice owner on their
        device. If you don’t have ability to do so, then use verification code
        tab.
      </p>

      {isVerified && (
        <SuccessModal
          open={isVerified}
          handleClose={() => setIsVerified(false)}
          successMessage={'Successfully Checked In'}
        />
      )}

      {openErrorModal && (
        <ErrorModal
          open={openErrorModal}
          handleClose={() => setOpenErrorModal(false)}
          errorMessage={'Not verified'}
        />
      )}

      {open && (
        <>
          <ProCheckInDialog
            open={open}
            handleClose={handleClose}
            verified={() => {
              setIsVerified(true);
            }}
            unverified={() => {
              setOpenErrorModal(true);
            }}
          />
        </>
      )}


      {checkoutDialoag && (
        <>
          <CheckoutDialog
            open={checkoutDialoag}
            handleClose={handleCloseCheckoutDialoag}
          />
        </>
      )}
    </div>
  );
}
