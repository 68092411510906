import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { FormGroup, Switch, FormControlLabel } from '@material-ui/core';

const MUISwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: alpha('#4CAF50', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#4CAF50',
  },
}));

const GreenSwitch = ({ checked, label, onChange }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<MUISwitch onChange={onChange} checked={checked} />}
        label={label}
      />
    </FormGroup>
  );
};

export default GreenSwitch;
