
import { Link } from "react-router-dom";

import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import leftShoulder from '../assets/images/leftShoulderLogin.jpg';
import rightShoulder from '../assets/images/rightShoulderLogin.jpg';
import logo from "../assets/icons/Logo.svg";

export default function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 3000)
  }, []);

  return (
    <Row className='justify-content-between p-0 m-0'>
      <Col className='d-none d-md-grid p-0 m-0' md={2}>
        <Image
          style={{ height: '100vh' }}
          className='img-fluid'
          src={leftShoulder}
        />
      </Col>
      <Col
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        className='m-0'
        md={8}
      >
        <Col xs={"auto"} xxl={4} className="text-center mx-auto mt-3">
          <Image className="my-3" src={logo} />
          <h2 className="mt-2 fw-semibold text-grey">Sorry!</h2>
          <div className="d-flex justify-content-center align-items-center mb-4">
            <hr className="pb-2 text-grey" style={{ width: "40px" }} />
            {/* <h6 className="fw-regular text-grey mx-5">  Not Found (404) </h6> */}
            <hr className="pb-2 text-grey" style={{ width: "40px" }} />
          </div>

          <div className="mt-3 text-grey">
            <h1 className="fw-regular text-grey">Page Not Found (404) </h1>
            <Link to={"/"} className="text-decoration-none">
              <span style={{ color: "#2561B0" }}>Go Back</span>
            </Link>
          </div>
        </Col>
        <p
          className='mt-auto text-center mx-auto text-lightgrey'
          style={{ fontSize: '0.73rem' }}
        >
          If you have any questions, please contact us at:{' '}
          <a href='https://maydaydentalstaffing.com'>
            maydaydentalstaffing.com
          </a>{' '}
          <br />. Mayday Dental Staffing is a registered trademark. Copyright ©
          Mayday Dental Staffing. All rights reserved. Patent pending.
        </p>
      </Col>
      <Col className='d-none d-md-grid p-0 m-0' md={2}>
        <Image
          style={{ height: '100vh' }}
          className='img-fluid ms-auto'
          src={rightShoulder}
        />
      </Col>
    </Row>
  );
}
