import React, { useEffect, useState } from 'react';
import Layout from '../../../../../components/Layout';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import ScheduleInterviewCalendar from '../ScheduleJobInterview/ScheduleInterviewCalendar';

const InterviewCalendar = () => {
  const { id } = useParams();
  const [events, setEvents] = useState([{}]);
  const [interviews, setInterviews] = useState([]);
  const [posting, setPosting] = useState({});

  const getInterviews = () => {
    axios
      .get(
        `https://api.mddentalstaffing.com/api/v1/owner/interview-schedules`,
        {
          headers: {
            method: 'GET',
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )
      .then((response) => {
        const interviews = response.data.data;

        const events = interviews.map((interview) => {
          return {
            title: interview.type,
            start: moment(interview.interview_date).toDate(),
            end: moment(interview.interview_date).toDate(),
            ...interview,
            interview: {
              ...interview.interview,
              applicant: {
                ...interview.interview.posting_applicant.user,
              },
            },
          };
        });

        const groupedEvents = events.reduce((acc, event) => {
          const date = moment(event.start).format('YYYY-MM-DD');
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(event);
          return acc;
        }, {});

        const eventList = Object.keys(groupedEvents).map((date) => {
          return {
            start: moment(date).toDate(),
            end: moment(date).toDate(),
            events: groupedEvents[date],
            title: `${groupedEvents[date].length} Interview(s) on this day`,
          };
        });
        setInterviews(interviews);
        setEvents(eventList);
      })
      .catch((e) => console.log(e));
  };

  const getPosting = () => {
    axios
      .get(`https://api.mddentalstaffing.com/api/v1/owner/postings/${id}`, {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((response) => {
        setPosting(response.data.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getInterviews();
  }, []);

  useEffect(() => {
    getPosting();
  }, []);

  return (
    <Layout
      items={[
        {
          name: 'Postings',
          link: '/',
        },
        {
          name: 'Permanent Job',
          link: '/owner/postings/permanent',
        },
        {
          name: 'Interview Calendar',
          link: `/owner/postings/permanent/interview-calendar/${id}`,
        },
      ]}
    >
      <div
        style={{
          padding: '20px',
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <h4 className='pb-0 mb-1' style={{ color: '#262626' }}>
            Interview Calendar
          </h4>
        </div>
      </div>

      {events && (
        <ScheduleInterviewCalendar
          interviews={interviews}
          events={events}
          setEvents={setEvents}
          posting={posting}
        />
      )}
    </Layout>
  );
};

export default InterviewCalendar;
