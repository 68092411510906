import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/Logo.svg';
import shoulder from '../../assets/images/registerProfessional.jpg';
import PersonalDetails from '../../components/PracticeOwnerReg/PersonalDetails';
import Speciality from '../../components/ProfessionalReg/Speciality';
import Questionnaire from '../../components/ProfessionalReg/Questionnaire';
import { selectUser } from '../../redux/slices/userSlice';

const ColorStepConnector = withStyles({
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,#00c853 0%, #00c853 100%)',
      height: 1.5,
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg, #00c853 0%, #00c853 100%)',
      height: 1.5,
    },
  },
  line: {
    height: 1.5, // Adjusted thickness
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    marginLeft: 12, // Adjusted margin
    marginRight: 12, // Adjusted margin
  },
})(StepConnector);

export default function ProfessionalReg() {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (!user.role_type) {
      navigate('/login');
    } else if (user && user.role_type === 'general') {
      navigate('/selectRole');
    } else if (user && user.role_type === 'owner') {
      navigate('/registration/owner');
    }

    if (user.steps_completed === 0) {
      setActiveStep(1);
    } else if (user.steps_completed === 1) {
      setActiveStep(2);
    } else if (user.steps_completed === 2) {
      setActiveStep(3);
    } else if (user.steps_completed === 3) {
      if (user.is_contract_signed === true) {
        navigate('/registration/completed');
      } else {
        navigate('/registration/agreement');
      }
    }
  }, [navigate, user]);

  const steps = [
    { label: 'Step 1', alternativeLabel: 'Personal Details' },
    { label: 'Step 2', alternativeLabel: 'Add Speciality' },
    { label: 'Step 3', alternativeLabel: 'Questionaire' },
  ];

  return (
    <>
      <Row
        style={{ width: '100vw', margin: '0' }}
        className='justify-content-between'
      >
        <Col className='p-0'>
          <Container className='mx-2 mx-md-5 my-2 my-md-4'>
            <Image src={logo} />
            <div className='text-grey fs-2 my-4 fw-semibold'>
              Create an account for Professional
            </div>
            <hr className='text-lightgrey' />
            <Row className='p-0 m-0 mb-2' style={{ width: '95%' }}>
              <Stepper
                activeStep={activeStep - 1}
                connector={<ColorStepConnector />}
                className='m-0 p-0'
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel className='text-start'>
                      <span className='fw-semibold'> </span>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Row>
            <Row
              className='d-none d-md-flex p-0 pe-3 m-0 mx-2 justify-content-between '
              style={{ width: '95%' }}
            >
              <Col className='px-0 mx-0' sm={3}>
                <span className='fw-semibold'>
                  Login Info / Personal Details
                </span>
                <br />
                <span style={{ fontSize: '0.8rem' }} className='text-lightgrey'>
                  Step 1
                </span>
              </Col>
              <Col className='px-0 mx-0 text-center' sm={3}>
                <span className='fw-semibold'>Add Specialty</span>
                <br />
                <span style={{ fontSize: '0.8rem' }} className='text-lightgrey'>
                  Step 2
                </span>
              </Col>
              <Col className='px-0 mx-0 text-end' sm={3}>
                <span className='fw-semibold mb-0'>Questionnaire</span>
                <br />
                <span style={{ fontSize: '0.8rem' }} className='text-lightgrey'>
                  Step 3
                </span>
              </Col>
            </Row>
            <br />
            {activeStep === 1 ? (
              <PersonalDetails setActiveStep={setActiveStep} />
            ) : activeStep === 2 ? (
              <Speciality setActiveStep={setActiveStep} />
            ) : activeStep === 3 ? (
              <Questionnaire setActiveStep={setActiveStep} />
            ) : null}
          </Container>
        </Col>
        <Col md={3} className='p-0 m-0 d-none d-md-flex justify-content-end'>
          <Image
            style={{ height: '100vh', position: 'fixed', width: '25%' }}
            src={shoulder}
          />
        </Col>
      </Row>
    </>
  );
}
