import React, { useEffect, useState } from 'react';
import Layout from '../../../../components/Layout';
import { Grid } from '@mui/material';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import ReactCalendar from './ReactCalendar';

const MainCalendar = () => {
  const [myJobs, setMyJobs] = useState([]);

  const authToken = localStorage.getItem('auth_token');

  useEffect(() => {
    getMyJobs();
  }, []);

  const getMyJobs = () => {
    axios
      .get(
        `https://api.mddentalstaffing.com/api/v1/postings?my_jobs=1&join_schedule=1`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => {
        setMyJobs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Layout
      items={[
        {
          name: 'Jobs',
          link: '/',
        },
        {
          name: 'Main Calendar',
          link: '/',
        },
      ]}
    >
      <div
        className='d-flex'
        style={{
          alignItems: 'center',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <Grid
          sx={{
            px: 3,
            pt: 2,
            pb: 1,
            width: 'auto',
          }}
        >
          <h4 className='pb-0 mb-1' style={{ color: '#262626' }}>
            Main Calendar
          </h4>
          <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
            Manage Main Calendar
          </p>
        </Grid>
      </div>
      <div
        className='d-flex justify-content-end'
        style={{
          borderBottom: '1px solid #D9D9D9',
          alignItems: 'center',
          padding: 16,
        }}
      >
        <Button
          variant='primary'
          style={{
            backgroundColor: '#2561B0',
            border: 0,
          }}
        >
          Export To My Calendar
        </Button>
      </div>
      {myJobs && myJobs.data && myJobs.data.length > 0 && (
        <ReactCalendar data={myJobs.data} />
      )}
    </Layout>
  );
};

export default MainCalendar;
