import React, { useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import leftShoulder from '../assets/images/leftShoulderLogin.jpg';
import rightShoulder from '../assets/images/rightShoulderLogin.jpg';
import ForgotPass from '../components/Login/ForgotPass';
import { selectUser } from '../redux/slices/userSlice';
import ForgotPassPhone from '../components/Login/ForgotPassPhone';

export default function ForgotPassPage() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const createType = localStorage.getItem('createType');

  useEffect(() => {
    if (user.role_type) {
      navigate('/login');
    }
  }, [navigate, user.role_type]);
  return (
    <Row className='justify-content-between p-0 m-0'>
      <Col className='d-none d-md-grid p-0 m-0' md={2}>
        <Image
          style={{ height: '100vh' }}
          className='img-fluid'
          src={leftShoulder}
        />
      </Col>

      <Col
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        className='px-3 m-0'
        md={8}
      >
        {createType && createType === 'phone' ? (
          <ForgotPassPhone />
        ) : (
          <ForgotPass />
        )}
        <p
          className='mt-auto text-center mx-auto text-lightgrey'
          style={{ fontSize: '0.73rem' }}
        >
          If you have any questions, please contact us at:{' '}
          <a href='https://maydaydentalstaffing.com'>
            maydaydentalstaffing.com
          </a>{' '}
          <br />. Mayday Dental Staffing is a registered trademark. Copyright ©
          Mayday Dental Staffing. All rights reserved. Patent pending.
        </p>
      </Col>

      <Col className='d-none d-md-grid p-0 m-0' md={2}>
        <Image
          style={{ height: '100vh' }}
          className='img-fluid ms-auto'
          src={rightShoulder}
        />
      </Col>
    </Row>
  );
}
