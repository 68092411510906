import CloseIcon from "@mui/icons-material/Close";
import {
	Chip,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";

export default function ForHygeinist({
	setAdditionalInfo,
	additionalInfo,
	setSpecialties,
	specialties,
	setYearsAsRDH,
	yearsAsRDH,
	setYearsWorked,
	yearsWorked,
	handleKeyDownPrc,
	handleKeyDown,
	handleChipDeletePrc,
	handleChipDelete,
	practiceManagementSoftware,
	setPrcValue,
	prcValue,
	radiographySystems,
	setRinputValue,
	rinputValue,
}) {
	return (
		<Row style={{ width: "95%" }}>
			<h4
				className="m-0 p-0 my-3 ms-3 fw-semibold"
				style={{ fontSize: "1.25rem" }}
			>
				Questionaire
			</h4>
			<Row className="m-0 p-0">
				<Col className="m-0 p-0 px-3" xs={12} md={6}>
					<h6 style={{ color: "#595959" }}>
						How many years/months did you work in dental field?*
					</h6>
					<Row className="m-0 p-0 mb-3">
						<Col className="m-0 p-0 pe-0 pe-md-2" xs={12} md={6}>
							<TextField
								size="small"
								required
								className="w-100"
								label="Years"
								variant="outlined"
								sx={{ mb: 2 }}
								value={yearsWorked.years}
								onChange={(e) =>
									setYearsWorked((state) => ({
										...state,
										years: e.target.value.replace(/\D/, ""),
									}))
								}
							/>
						</Col>
						<Col className="m-0 p-0 ps-0 ps-md-2" xs={12} md={6}>
							<TextField
								required
								size="small"
								className="w-100"
								label="Months"
								variant="outlined"
								sx={{ mb: 2 }}
								value={yearsWorked.months}
								onChange={(e) => {
									let value = e.target.value.replace(/\D/, "");
									if (value > 12) value = "12";
									setYearsWorked((state) => ({
										...state,
										months: value,
									}));
								}}
							/>
						</Col>
					</Row>
					<h6 style={{ color: "#595959" }}>
						Proficient with the following Digital Radiography Systems{" "}
						<span className="text-lightgrey"> (Up to 50): </span>
					</h6>
					<Row className="m-0 p-0">
						<TextField
							required
							size="small"
							variant="outlined"
							sx={{
								mb: 2,
								display: "flex",
								flexWrap: "wrap",
								height: "auto",
								overflowY: "auto",
								"&::-webkit-scrollbar": {
									height: "0.3rem",
								},
								"&::-webkit-scrollbar-thumb": {
									borderRadius: "6px",
									backgroundColor: "#bfbfbf",
								},
								"&::-webkit-scrollbar-track": {
									borderRadius: "6px",
									backgroundColor: "#eee",
								},
								"&::-webkit-scrollbar-button": {
									display: "none",
								},
							}}
							value={rinputValue}
							onChange={(e) => setRinputValue(e.target.value)}
							onKeyDown={handleKeyDown}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{radiographySystems.map((system, index) => (
											<Chip
												color="primary"
												className="me-1 rounded"
												key={index}
												label={system}
												onDelete={() => handleChipDelete(index)} // Modified line to handle chip deletion
												deleteIcon={<CloseIcon />} // Assuming you have an icon component for the "X"
											/>
										))}
									</InputAdornment>
								),
							}}
						/>
					</Row>
				</Col>
				<Col className="m-0 p-0 px-3" xs={12} md={6}>
					<h6 style={{ color: "#595959" }}>How many years/months as an RDH?</h6>
					<Row className="m-0 p-0 mb-3">
						<Col className="m-0 p-0 pe-0 pe-md-2" xs={12} md={6}>
							<TextField
								size="small"
								className="w-100"
								label="Years"
								variant="outlined"
								sx={{ mb: 2 }}
								value={yearsAsRDH.years}
								onChange={(e) =>
									setYearsAsRDH((state) => ({
										...state,
										years: e.target.value.replace(/\D/, ""),
									}))
								}
							/>
						</Col>
						<Col className="m-0 p-0 ps-0 ps-md-2" xs={12} md={6}>
							<TextField
								size="small"
								className="w-100"
								label="Months"
								variant="outlined"
								sx={{ mb: 2 }}
								value={yearsAsRDH.months}
								onChange={(e) => {
									let value = e.target.value.replace(/\D/, "");
									if (value > 12) value = "12";
									setYearsAsRDH((state) => ({
										...state,
										months: value,
									}));
								}}
							/>
						</Col>
					</Row>
					<h6 style={{ color: "#595959" }}>
						Proficient with the following Practice Management Software{" "}
						<span className="text-lightgrey"> (Up to 50): </span>
					</h6>
					<Row className="m-0 p-0">
						<TextField
							size="small"
							required
							className="w-100"
							variant="outlined"
							sx={{
								mb: 2,
								display: "flex",
								flexWrap: "wrap",
								height: "auto",
								overflowY: "auto",
								"&::-webkit-scrollbar": {
									height: "0.3rem",
								},
								"&::-webkit-scrollbar-thumb": {
									borderRadius: "6px",
									backgroundColor: "#bfbfbf",
								},
								"&::-webkit-scrollbar-track": {
									borderRadius: "6px",
									backgroundColor: "#eee",
								},
								"&::-webkit-scrollbar-button": {
									display: "none",
								},
							}}
							value={prcValue}
							onChange={(e) => setPrcValue(e.target.value)}
							onKeyDown={handleKeyDownPrc}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										{practiceManagementSoftware.map((system, index) => (
											<Chip
												color="primary"
												className="me-1 rounded"
												key={index}
												label={system}
												onDelete={() => handleChipDeletePrc(index)}
												deleteIcon={<CloseIcon />}
											/>
										))}
									</InputAdornment>
								),
							}}
						/>
					</Row>
				</Col>
			</Row>

			<h4
				className="m-0 p-0 my-3 ms-3 fw-semibold"
				style={{ fontSize: "1.25rem" }}
			>
				Experienced in the following specialties:
			</h4>
			<Row className="m-0 p-0">
				<Col className="m-0 p-0 px-3" xs={12} md={6}>
					<Row className="m-0 p-0 mb-3">
						<Col className="m-0 p-0 pe-0 pe-md-2" xs={12} md={6}>
							<FormControl size="small" className="w-100">
								<InputLabel id="category-label">General</InputLabel>
								<Select
									required
									className="w-100"
									labelId="category-label"
									id="category"
									label="General"
									sx={{ mb: 2 }}
									value={specialties.general}
									onChange={(e) =>
										setSpecialties((state) => ({
											...state,
											general: e.target.value,
										}))
									}
								>
									<MenuItem value="Yes">Yes</MenuItem>
									<MenuItem value="Some">Some</MenuItem>
									<MenuItem value="No">No</MenuItem>
								</Select>
							</FormControl>
						</Col>
						<Col className="m-0 p-0 ps-0 ps-md-2" xs={12} md={6}>
							<FormControl size="small" className="w-100">
								<InputLabel id="category-label">Pedo</InputLabel>
								<Select
									required
									className="w-100"
									labelId="category-label"
									id="category"
									label="Pedo"
									sx={{ mb: 2 }}
									value={specialties.pedo}
									onChange={(e) =>
										setSpecialties((state) => ({
											...state,
											pedo: e.target.value,
										}))
									}
								>
									<MenuItem value="Yes">Yes</MenuItem>
									<MenuItem value="Some">Some</MenuItem>
									<MenuItem value="No">No</MenuItem>
								</Select>
							</FormControl>
						</Col>
					</Row>
				</Col>
				<Col className="m-0 p-0 px-3" xs={12} md={6}>
					<Row className="m-0 p-0 mb-3">
						<Col className="m-0 p-0 pe-0 pe-md-2" xs={12} md={6}>
							<FormControl size="small" className="w-100">
								<InputLabel id="category-label">Perio</InputLabel>
								<Select
									required
									className="w-100"
									labelId="category-label"
									id="category"
									label="Perio"
									sx={{ mb: 2 }}
									value={specialties.perio}
									onChange={(e) =>
										setSpecialties((state) => ({
											...state,
											perio: e.target.value,
										}))
									}
								>
									<MenuItem value="Yes">Yes</MenuItem>
									<MenuItem value="Some">Some</MenuItem>
									<MenuItem value="No">No</MenuItem>
								</Select>
							</FormControl>
						</Col>
					</Row>
				</Col>
			</Row>

			<h4
				className="m-0 p-0 my-3 ms-3 fw-semibold"
				style={{ fontSize: "1.25rem" }}
			>
				Additional Information
			</h4>
			<Row className="m-0 p-0">
				<Col className="m-0 p-0 px-3" xs={12} md={6}>
					<Row className="m-0 p-0">
						<FormControl size="small">
							<InputLabel id="category-label">
								Do you feel comfortable working with Nitrous Oxide?
							</InputLabel>
							<Select
								required
								className="w-100"
								labelId="category-label"
								id="category"
								label="Do you feel comfortable working with Nitrous Oxide?"
								sx={{ mb: 2 }}
								value={additionalInfo.nitrousOxide}
								onChange={(e) =>
									setAdditionalInfo((state) => ({
										...state,
										nitrousOxide: e.target.value,
									}))
								}
							>
								<MenuItem value="Yes">Yes</MenuItem>
								<MenuItem value="Some">Some</MenuItem>
								<MenuItem value="No">No</MenuItem>
							</Select>
						</FormControl>
					</Row>
					<Row className="m-0 p-0">
						<FormControl size="small">
							<InputLabel id="category-label">Experienced in Pano:</InputLabel>
							<Select
								required
								className="w-100"
								labelId="category-label"
								id="category"
								label="Experienced in Pano:"
								sx={{ mb: 2 }}
								value={additionalInfo.pano}
								onChange={(e) =>
									setAdditionalInfo((state) => ({
										...state,
										pano: e.target.value,
									}))
								}
							>
								<MenuItem value="Yes">Yes</MenuItem>
								<MenuItem value="Some">Some</MenuItem>
								<MenuItem value="No">No</MenuItem>
							</Select>
						</FormControl>
					</Row>
					<Row className="m-0 p-0">
						<FormControl size="small">
							<InputLabel id="category-label">
								Do you feel comfortable applying Arestin and other
								anti-microbials?
							</InputLabel>
							<Select
								required
								className="w-100"
								labelId="category-label"
								id="category"
								label="Do you feel comfortable applying Arestin and other anti-microbials?"
								sx={{ mb: 2 }}
								value={additionalInfo.applyingAntiMicrobials}
								onChange={(e) =>
									setAdditionalInfo((state) => ({
										...state,
										applyingAntiMicrobials: e.target.value,
									}))
								}
							>
								<MenuItem value="Yes">Yes</MenuItem>
								<MenuItem value="Some">Some</MenuItem>
								<MenuItem value="No">No</MenuItem>
							</Select>
						</FormControl>
					</Row>
				</Col>
				<Col className="m-0 p-0 px-3" xs={12} md={6}>
					<Row className="m-0 p-0">
						<FormControl size="small">
							<InputLabel id="category-label">
								Can you anesthetize by yourself?
							</InputLabel>
							<Select
								required
								className="w-100"
								labelId="category-label"
								id="category"
								label="Can you anesthetize by yourself?"
								sx={{ mb: 2 }}
								value={additionalInfo.anesthesizeSelf}
								onChange={(e) =>
									setAdditionalInfo((state) => ({
										...state,
										anesthesizeSelf: e.target.value,
									}))
								}
							>
								<MenuItem value="Yes">Yes</MenuItem>
								<MenuItem value="Some">Some</MenuItem>
								<MenuItem value="No">No</MenuItem>
							</Select>
						</FormControl>
					</Row>
					<Row className="m-0 p-0">
						<FormControl size="small">
							<InputLabel id="category-label">
								Experienced in Intraoral Cameras:
							</InputLabel>
							<Select
								required
								className="w-100"
								labelId="category-label"
								id="category"
								label="Experienced in Intraoral Cameras:"
								sx={{ mb: 2 }}
								value={additionalInfo.intraoralCameras}
								onChange={(e) =>
									setAdditionalInfo((state) => ({
										...state,
										intraoralCameras: e.target.value,
									}))
								}
							>
								<MenuItem value="Yes">Yes</MenuItem>
								<MenuItem value="Some">Some</MenuItem>
								<MenuItem value="No">No</MenuItem>
							</Select>
						</FormControl>
					</Row>
				</Col>
			</Row>
		</Row>
	);
}
