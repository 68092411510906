import React from 'react';
import logo from '../../assets/images/Logo.png';
import './Loader.css';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <img
        className='loader'
        style={{
          height: '50px',
          width: '50px',
        }}
        src={logo}
        alt=''
      />
    </div>
  );
};

export default Loader;
