import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SvgIcon from '@mui/material/SvgIcon';
import Icon from '@mui/material/Icon';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Close from '@mui/icons-material/Close';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from "react-redux";

import { TextField } from "@mui/material";
import Grid from '@mui/material/Grid';


import {
    setIscheckedIn,
    getCheckedInStatus,
    fetchIscheckedIn,
    selectIscheckedIn
} from '../../redux/slices/checkInSlice';

import { useSelector } from 'react-redux';

export default function CheckoutDialog({ open, handleClose }) {

    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = React.useState(100);
    const ischeckedIn = useSelector(selectIscheckedIn);


    useEffect(() => {

    }, [])


    const checkout = async (payload) => {
        setIsLoading(true);
        axios.get(`https://api.mddentalstaffing.com/api/v1/check-out`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            }
        }).then((response) => {

            if (response.data.data.checkout) {
                dispatch(setIscheckedIn(false));
                handleClose();
            }
            setIsLoading(false);

        }).catch((e) => {
            setIsLoading(false);
            console.log(e.message);
        });
    };


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // fullWidth='sm'
                // maxWidth='sm'
                // fullScreen={fullScreen}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "30%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Check Out"}
                    </DialogTitle>
                </div>
                <DialogContent >
                    <Box sx={{ typography: 'body1' }}>
                        Are you sure to want check out
                    </Box>
                </DialogContent>
                <DialogActions>
                    <hr />
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={checkout} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}