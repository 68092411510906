import { CircularProgress, Modal } from '@mui/material';

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Container } from 'react-bootstrap';
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import logo from '../../assets/icons/Logo.svg';
import crown from '../../assets/icons/Crown.svg';
import svgModal from '../../assets/images/Dialog.png';
import hand from '../../assets/images/handPhone.png';
import { setUser } from '../../redux/slices/userSlice';
import SubscriptionPlan from './SubscriptionPlan';

import CheckoutForm from './CheckoutForm';
import shoulder from '../../assets/images/registerPracticeOwner.jpg';


import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";


export default function PaymentMethod() {
  const stripePromise = loadStripe("pk_test_51EsUJQCdRcJh67FUP7Ajf7V03zXzGxPs6TdzYsku2J8oQLgrSYImKhMrFWMAL2Z2K93XsMKl1Za4lPMaJoh1mZzn00ibjyh3x1");
  const [selectedValue, setSelectedValue] = useState('1_1');
  const [subs, setSubs] = useState([]);
  const [plan, setSubscription] = useState([]);
  const [subPlan, setSubPlan] = useState(null);
  const [subPlanPrice, setSubPlanPrice] = useState(null);
  const [subPlanDuration, setSubPlanDuration] = useState(null);
  const [subPlanCycle, setSubPlanCycle] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [setupIntent, setSetupIntent] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchPlan = async function (plan_id, subscription_plan_id) {
    return new Promise((resolve, reject) => {
      axios.get(`https://api.mddentalstaffing.com/api/v1/subscriptions?plan_id=${plan_id}&subscription_plan_id=${subscription_plan_id}`)
        .then(response => {
          resolve(response.data.data[0])
        }).catch(error => {
          reject(error)
        })
    });
  }

  const fetchIntent = async function () {
    const authToken = localStorage.getItem('auth_token');
    return new Promise((resolve, reject) => {
      const setup_intent_response = axios.post(`https://api.mddentalstaffing.com/api/v1/intent`, {}, { headers: { Authorization: `Bearer ${authToken}` } }).then((res) => {
        setPaymentDialog(true);
        setClientSecret(res.data.setup_intent.client_secret);
        // setClientSecret(res.data.clientSecret); // For PaymentIntent PaymentElement
        setSetupIntent(res.data.setup_intent);
      }).catch((e) => {
        setError(e.message);
      });
    })
  }

  useEffect(() => {

    setIsLoading(true);
    const plan_id = localStorage.getItem("plan_id");
    const subscription_plan_id = localStorage.getItem("subscription_plan_id");

    if (!plan_id && !subscription_plan_id) {
      navigate(-1);
    }
    async function fetchData() {
      const response = await fetchPlan(plan_id, subscription_plan_id).then((res) => {
        if (!ignore) {
          setSubs(res);
          setSubPlan((...subPlan) => [...subPlan, res.subscription_plans[0]]);
          setSubPlanDuration(res.subscription_plans[0].cycle_type);
          setSubPlanPrice(res.subscription_plans[0].amount);
          setSubPlanCycle(res.subscription_plans[0].cycle);
          setIsLoading(false);
        }
      });

      const intent_response = await fetchIntent((res) => {
        setPaymentDialog(true);
        setClientSecret(res.data.setup_intent.client_secret);
        setSetupIntent(res.data.setup_intent);
      })
    };

    let ignore = false;
    fetchData();
    return () => {
      ignore = true;
    }

  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0570de',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px',
      // See all possible variables below
    }
  };

  const handleComplete = (event) => {
  }

  const CardPlaceHolder = () => {
    return (
      <Container>
        <Placeholder as={Card.Title} animation="glow" className="h-100 mb-3 h-100" style={{ height: '50px' }}>
          <Placeholder style={{ height: '35px' }} xs={6} /> <Placeholder style={{ height: '35px' }} xs={5} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          <Placeholder style={{ height: '35px' }} xs={5} /> <Placeholder style={{ height: '35px' }} xs={6} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          <Placeholder style={{ height: '35px' }} xs={11} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          <Placeholder style={{ height: '35px' }} xs={11} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          <Placeholder style={{ height: '35px' }} xs={11} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow" className="mb-3">
          {/* <Placeholder style={{ height: '35px' }} xs={5} />
        <Placeholder style={{ height: '35px' }} xs={6} /> */}
          <Placeholder.Button bg="light" xs={5} aria-hidden="true" /> <Placeholder.Button xs={6} aria-hidden="true" />
        </Placeholder>
      </Container>
    )
  }

  const PlanPlaceHolder = () => {
    return (
      <Card.Body>
        <Placeholder as={Card.Title} animation="glow" className="mb-5">
          <Placeholder xs={6} style={{ height: '35px' }} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow" className="mb-8">
          <Placeholder xs={7} style={{ height: '20px' }} /><Placeholder xs={2} style={{ height: '20px' }} /> <Placeholder style={{ height: '35px' }} xs={4} />{' '}
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow" className="mb-3 mt-5">
          <Placeholder style={{ height: '25px' }} xs={6} /><Placeholder style={{ height: '25px' }} xs={8} />
        </Placeholder>
      </Card.Body>
    )
  }


  return (
    <>
      <Row
        style={{ width: '100vw', margin: '0' }}
        className='justify-content-between'
      >
        <Col className='p-0'>
          <Container className='mx-2 mx-md-5 my-2 my-md-4'>
            <Image src={logo} />
            {/* <div className='text-grey fs-2 my-4 fw-semibold'>
              Payment Card Detail
            </div> */}
            <div className='w-lg-75' style={{ width: '95%' }}>
              <hr />
              <Row className='justify-content-between mx-0 my-5'>
                <Col
                  className={`justify-content-center rounded mb-5 mt-2 py-4 px-3`}
                  style={{
                    overflow: 'hidden',
                    backgroundColor: '#FAFAFA',
                    flex: '0 0 35.5%', // Custom column width
                    maxWidth: '35.5%', // Custom column width
                    boxShadow: '0 4px 50px rgba(0,0,0,0.1)',
                  }}
                >
                  {isLoading ? (
                    <PlanPlaceHolder />
                  ) : (
                    <div style={{ backgroundColor: '#FAFAFA' }}>
                      <h4> {subs.name}</h4>
                      <p style={{ fontSize: '0.9rem', color: '#595959' }} className='w-75'>
                        {subs.features}
                      </p>
                      <br />
                      <p className='mb-0 text-lightgrey'>Starts at</p>
                      <span className='fw-semibold display-6'>
                        $ {subPlanPrice}
                      </span>
                      <span className='text-lightgrey fs-5 fw-semibold'>
                        /  {subPlanDuration}
                      </span>
                      <div
                        className="mt-3"
                        style={{
                          border: "none",
                          borderRadius: "0.5rem",
                          padding: "10px",
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          className={"border border-2 rounded mb-3"}
                        >
                          <Grid item xs={1} className="p-3">
                            <FormControl component="fieldset">
                              <RadioGroup
                              >
                                <FormControlLabel
                                  control={<Radio color="primary" />}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          <Grid className="d-flex ms-3 align-items-center" item xs={6}>
                            <div className="text-start">
                              <label
                                className="text-start"
                              >
                                {subPlanCycle} Month Plan
                                <br />{" "}
                                {/* <span className="fw-semibold">0.00</span> */}
                              </label>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  )}
                </Col>

                <Col
                  className={`justify-content-center rounded mb-5 mt-2 py-4 px-3`}
                  style={{
                    overflow: 'hidden',
                    backgroundColor: '#FAFAFA',
                    flex: '0 0 60%', // Custom column width
                    maxWidth: '60%', // Custom column width
                    boxShadow: '0 4px 50px rgba(0,0,0,0.1)',
                  }}
                >
                  <div style={{ backgroundColor: '#FAFAFA' }}>
                    <Row className='ms-1 mb-4'>
                      <Col xs={12} md={6} className=""><h4> Card Details</h4></Col>
                    </Row>
                    {!paymentDialog ? (
                      <CardPlaceHolder />
                    ) : (
                      <Elements
                        stripe={stripePromise}
                        options={{
                          clientSecret,
                          onComplete: handleComplete,
                          appearance: appearance
                        }}>
                        <CheckoutForm price={subPlanPrice} setup_intent={setupIntent} />
                      </Elements>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Col>
        <Col md={3} className='p-0 m-0 d-none d-md-flex justify-content-end'>
          <Image
            style={{ height: '100vh', position: 'fixed', width: '25%' }}
            src={shoulder}
          />
        </Col>
      </Row >
    </>
  );
}
