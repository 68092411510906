import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Button } from 'react-bootstrap';
import FilterIcon from '../../../assets/icons/filter.svg';
import RefreshIcon from '../../../assets/icons/arrow-clockwise.svg';
import CustomDataGrid from '../../../components/General/CustomDataGrid';
import TransactionDetailsModal from './TransactionDetailsModal';
import FiltersSidebar from './FiltersSidebar';
import axios from 'axios';
import moment from 'moment';

const Subscription = () => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [isFiltersSidebarOpen, setIsFiltersSidebarOpen] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [transaction, setTransaction] = useState([]);

  const columns = [
    { field: 'start_at', headerName: 'Start Date', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'cycle_type', headerName: 'Cycle Type', width: 150 },
    { field: 'stripe_status', headerName: 'Stripe Status', width: 150 },
    { field: 'amount', headerName: 'Amount', width: 50 },
    { field: 'action', headerName: 'Action', width: 50 },
  ];

  const getTransaction = (page, limit) => {
    let endpoint = `https://api.mddentalstaffing.com/api/v1/owner/subscriptions?page=${page}&limit=${limit}`;
    axios.get(endpoint, {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      },
    }).then((response) => {
      setTransaction(response.data);
    }).catch((e) => console.log(e));
  };

  const rows = transaction?.data?.map((item) => {
    return {
      id: item.id,
      start_at: moment(item.start_at).utc().format('DD:MM:YY HH:MM A'),
      name: item.name,
      cycle_type: item.cycle_type,
      stripe_status: item.stripe_status,
      amount: item.amount,
      action: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <span
            style={{
              color: '#2561B0',
              fontWeight: 400,
              fontSize: '14px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSelectedItem(item);
              console.log('Test', item);
            }}
          >
            Cancel Subscription
          </span>
        </div>

      ),
    };
  });


  useEffect(() => {
    getTransaction(1, 10);
  }, []);

  return (
    <Layout
      items={[
        {
          name: 'Subscription',
          link: '/owner/Subscriptions',
        },
      ]}
      basicHeader={{
        title: 'Subscription',
        description: 'All Subscription',
      }}
    >
      <div
        className='d-flex justify-content-end'
        style={{
          backgroundColor: '#F5F5F5',
          padding: '12px 20px',
          borderBottom: '1px solid #D9D9D9',
          gap: 20,
        }}
      >
        <Button
          style={{
            border: '1px solid #2561B0',
            color: '#595959',
            backgroundColor: '#2561B0',
          }}
          onClick={() => setIsFiltersSidebarOpen(true)}
        >
          <img src={FilterIcon} alt='' />
          <span
            style={{
              marginLeft: 10,
              color: '#fff',
            }}
          >
            Filters
          </span>
        </Button>
        <Button
          style={{
            border: '1px solid #2561B0',
            color: '#595959',
            backgroundColor: '#fff',
          }}
          onClick={() => getTransaction(1, 10)}
        >
          <img src={RefreshIcon} alt='' />
          <span
            style={{
              marginLeft: 10,
              color: '#2561B0',
            }}
          >
            Reset filters
          </span>
        </Button>
      </div>


      {transaction?.data && (
        <CustomDataGrid
          columns={columns}
          rows={rows}
          paging={transaction.paging}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}


      {detailsModal && selectedItem && (
        <TransactionDetailsModal
          open={detailsModal}
          handleClose={() => setDetailsModal(false)}
          selectedItem={selectedItem}
          fetchData={() => { }}
        />
      )}

      {isFiltersSidebarOpen && (
        <FiltersSidebar
          isSidebarOpen={isFiltersSidebarOpen}
          setIsSidebarOpen={setIsFiltersSidebarOpen}
          setTransaction={setTransaction}
        />
      )}
    </Layout>
  );
};

export default Subscription;
