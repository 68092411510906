import { Person2Outlined } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/Layout';
import PaymentMethodModal from './PaymentMethodModal';
import moment from 'moment';
import { selectUser, setUser } from '../../../redux/slices/userSlice';
import CustomDataGrid from '../../../components/General/CustomDataGrid';
import CardForm from '../../../components/General/CardForm';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import RefreshIcon from "../../../assets/icons/arrow-clockwise.svg";
import Stack from '@mui/material/Stack';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';

const Billing = () => {

  const currentUser = useSelector(selectUser);
  const dispatch = useDispatch();
  const [billingCards, setBillingCards] = useState([]);
  const [defualtCard, setDefualtCard] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [paymentFormModal, setPaymentFormModal] = useState(false);

  const [defualtPaymentMethodModal, setDefualtPaymentMethodModal] = useState(false);

  const buttonStyle = {
    backgroundColor: isHovered ? '#b28900' : '#FFCF33',
    textTransform: 'none',
    padding: '7px 18px',
    color: '#262626',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
  };

  const getBillingCards = async () => {
    axios.get(
      `https://api.mddentalstaffing.com/api/v1/owner/billing-cards`,
      {
        headers: {
          method: 'GET',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      }
    )
      .then((res) => {
        setBillingCards(res.data.data.cards);
        setDefualtCard(res.data.data.default_payment_method);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getBillingCards();
  }, []);


  useEffect(() => {
    console.log('billingCards ==== > ', billingCards);
  }, [billingCards]);


  const columns = [
    { field: 'Card', headerName: 'Card', width: 200 },
    { field: 'LAST_Number', headerName: 'Last Number', width: 200 },
    { field: 'Expire', headerName: 'Expire', width: 150 },
    { field: 'Action', headerName: 'Defualt', width: 150 },
  ];



  const cards = billingCards?.map((item) => {
    return {
      id: item.id,
      Card: item.card.display_brand.toUpperCase(),
      Expire: item.card.exp_month + '/' + item.card.exp_year,
      LAST_Number: `••••••••••••••••${item.card.last4}`,
      Action: (
        <>
          <FormControlLabel
            value='Defualt'
            checked={item.id == defualtCard?.id ? true : false}
            onChange={() => {
              setDefualtPaymentMethodModal(true);
              setSelectedItem(item);
            }}
            control={<Radio />}
            label='Defualt Card'
          />
        </>
      ),
      ...item,
    };
  });


  useEffect(() => {
    console.log('billingCards === > ', billingCards);
  }, [billingCards]);

  return (
    <Layout
      items={[
        {
          name: 'My Account',
          link: '/',
          icon: <Person2Outlined sx={{ py: 0.2 }} />,
        },
        {
          name: 'Billing Profile',
          link: '/owner/account/billing',
          icon: (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='15'
              height='15'
              viewBox='0 0 20 20'
              fill='none'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M16.666 0H3.33268V1.66667H16.666V0ZM16.666 3.33333H3.33268C2.41602 3.33333 1.66602 4.08333 1.66602 5V15C1.66602 15.9167 2.41602 16.6667 3.33268 16.6667H16.666C17.5827 16.6667 18.3327 15.9167 18.3327 15V5C18.3327 4.08333 17.5827 3.33333 16.666 3.33333ZM16.666 15H3.33268V5H16.666V15ZM3.33268 18.3333H16.666V20H3.33268V18.3333ZM14.9993 11.6667H4.99935V13.3333H14.9993V11.6667ZM4.99935 9.16667H14.9993V10.8333H4.99935V9.16667ZM14.9993 6.66667H4.99935V8.33333H14.9993V6.66667Z'
                fill={'#0000008A'}
              />
            </svg>
          ),
        },
      ]}
    >
      <Grid
        sx={{
          px: 3,
          pt: 2,
          pb: 1,
          borderBottom: '1px solid #D9D9D9',
          width: 'auto',
        }}
      >
        <h4 className='pb-0 mb-1' style={{ color: '#262626' }}>
          Billing
        </h4>
        <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
          General Settings of Billings Cards
        </p>
      </Grid>

      <div
        className='d-flex justify-content-between'
        style={{
          backgroundColor: '#F5F5F5',
          padding: '12px 20px',
          borderBottom: '1px solid #D9D9D9',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 20,
            alignItems: 'center',
          }}
        >
          <Button
            variant='text'
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              console.log("Punjab");
              setPaymentFormModal(true);
            }}

          >
            <QrCodeScannerRoundedIcon
              sx={{ mx: 0.5 }}
              style={{ fontSize: '1.2rem' }}
            />{' '}
            Add New Payment Card
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 20,
          }}
        >
          <Button
            style={{
              border: "1px solid #2561B0",
              color: "#595959",
              backgroundColor: "#fff",
            }}
            onClick={() => getBillingCards()}
          >
            <img src={RefreshIcon} alt="" />
          </Button>
        </div>
      </div>


      <div>
        {billingCards && (
          <CustomDataGrid
            rows={cards}
            columns={columns}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
      </div>



      {defualtPaymentMethodModal && (
        <PaymentMethodModal
          open={defualtPaymentMethodModal}
          handleClose={() => setDefualtPaymentMethodModal(false)}
          selectedItem={selectedItem}
          fetchData={() => getBillingCards()}
        />
      )}

      {paymentFormModal && (
        <CardForm
          open={paymentFormModal}
          handleClose={() => setPaymentFormModal(false)}
          getBillingCards={() => getBillingCards()}
        />
      )}
    </Layout>
  );
};

export default Billing;
