import React, { useState, useMemo } from 'react';
import NavbarOwner from './Navbar/NavbarOwner';
import SideDrawer from './Navbar/Drawer';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slices/userSlice';
import ProfessionalDrawer from './Navbar/ProfessionalDrawer';
import NavigationPanel from './Navbar/NavigationPanel';
import { Grid } from '@mui/material';




const Layout = ({ children, items, basicHeader }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const user = useSelector(selectUser);
  const mainNavBar = React.useMemo(() => (<NavbarOwner
    setIsSidebarOpen={setIsSidebarOpen}
    isSidebarOpen={isSidebarOpen}
  />), []);
  return (
    <>
      <div style={{ position: 'relative', zIndex: 100 }}>
        {/* <NavbarOwner
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        /> */}
        {mainNavBar}
      </div>
      <div className='d-flex'>
        {user.role_type === 'owner' ? (
          <SideDrawer
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        ) : (
          <ProfessionalDrawer
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        )}
        <div
          style={{
            width: isSidebarOpen ? 'calc(100% - 256px)' : '100%',
            transition: 'all 0.3s ease',
            minHeight: 'calc(100vh - 256px)',
            backgroundColor: '#FAFAFA',
          }}
        >
          {items && <NavigationPanel items={items} />}
          {basicHeader && (
            <Grid
              sx={{
                px: 3,
                pt: 2,
                pb: 1,
                borderBottom: '1px solid #D9D9D9',
                width: 'auto',
              }}
            >
              <h4 className='pb-0 mb-1' style={{ color: '#262626' }}>
                {basicHeader.title}
              </h4>
              <p style={{ color: '#8C8C8C', fontSize: '0.8rem' }}>
                {basicHeader.description}
              </p>
            </Grid>
          )}
          <div>{children}</div>
          <p
            className='mt-auto text-center mx-auto text-lightgrey p-1'
            style={{ fontSize: '0.73rem' }}
          >
            If you have any questions, please contact us at:{' '}
            <a href='https://maydaydentalstaffing.com'>
              maydaydentalstaffing.com
            </a>{' '}
            <br />. Mayday Dental Staffing is a registered trademark. Copyright
            © Mayday Dental Staffing. All rights reserved. Patent pending.
          </p>
        </div>
      </div>
    </>
  );
};

export default Layout;
