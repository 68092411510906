import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/icons/Logo.svg';
import leftShoulder from '../assets/images/leftShoulderLogin.jpg';
import rightShoulder from '../assets/images/rightShoulderLogin.jpg';
import { selectUser } from '../redux/slices/userSlice';

export default function Congratulations() {
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (!user.role_type) {
      navigate('/login');
    } else if (user.steps_completed !== 3) {
      navigate(`/registration/${user.role_type}`);
    }
  }, [navigate, user]);

  return (
    <Row className='justify-content-between p-0 m-0'>
      <Col className='d-none d-md-grid p-0 m-0' md={2}>
        <Image
          style={{ height: '100vh' }}
          className='img-fluid'
          src={leftShoulder}
        />
      </Col>

      <Col
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        className='px-3 m-0'
        md={8}
      >
        <Image
          className='mb-5 mt-3 mx-auto'
          style={{ width: '10rem' }}
          src={logo}
        />
        <Col md={6} className='text-center mx-auto my-auto'>
          <h1 className='mt-2 mb-3 fw-semibold text-grey'>Congratulations!</h1>
          <div className=''>
            <h6 className='fw-regular mx-2'>
              You have successfully registered with MayDay Dental Staffing.{' '}
            </h6>

            <br />
            <Button
              variant='contained'
              color='primary'
              sx={{
                width: '60%',
                borderRadius: '4px',
                height: '56px',
                background: '#2561B0',
                boxShadow: 'none',
                textTransform: 'none',
              }}
              onClick={() => {
                if (
                  user &&
                  user.role_type === 'owner' &&
                  parseInt(user.steps_completed) === 3
                ) {
                  navigate('/');
                } else if (
                  user &&
                  user.role_type === 'professional' &&
                  parseInt(user.steps_completed) === 3
                ) {
                  navigate('/');
                }
              }}
            >
              Ok
            </Button>
          </div>
        </Col>

        <p
          className='mt-auto text-center mx-auto text-lightgrey'
          style={{ fontSize: '0.73rem' }}
        >
          If you have any questions, please contact us at:{' '}
          <a href='https://maydaydentalstaffing.com'>
            maydaydentalstaffing.com
          </a>{' '}
          <br />. Mayday Dental Staffing is a registered trademark. Copyright ©
          Mayday Dental Staffing. All rights reserved. Patent pending.
        </p>
      </Col>

      <Col className='d-none d-md-grid p-0 m-0' md={2}>
        <Image
          style={{ height: '100vh' }}
          className='img-fluid ms-auto'
          src={rightShoulder}
        />
      </Col>
    </Row>
  );
}
