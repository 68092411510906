import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import leftShoulder from '../assets/images/leftShoulderLogin.jpg';
import rightShoulder from '../assets/images/rightShoulderLogin.jpg';
import Login from '../components/Login/Login';
import { setAuthToken, setIsloggedin } from '../redux/slices/authSlice';
import { selectAuthToken, selectIsloggedin } from "../redux/slices/authSlice";
import { selectUser, setUser } from '../redux/slices/userSlice';

export default function LoginPage() {
  const user = useSelector(selectUser);
  const authToken = useSelector(selectAuthToken);
  const isloggedin = useSelector(selectIsloggedin);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.role_type && user.role_type === 'general') {
      navigate('/selectRole');
    } else if (user.role_type && user.role_type === 'owner') {
      if (user.is_profile_completed) {
        navigate('/');
      } else {
        navigate('/registration/owner');
      }
    } else if (user.role_type && user.role_type === 'professional') {
      if (user.is_profile_completed) {
        navigate('/');
      } else {
        navigate('/registration/professional');
      }
    }
  }, [navigate, user.role_type, user.is_profile_completed]);

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async (email, password) => {
    setIsLoading(true);

    const requestData = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(
        'https://api.mddentalstaffing.com/api/v1/login',
        requestData
      );
      const userData = response.data.data;
      userData['user']['authToken'] = userData.token;
      localStorage.setItem('auth_token', userData.token);
      localStorage.setItem('user', JSON.stringify(userData.user));
      dispatch(setUser(userData.user));
      dispatch(setAuthToken(userData.token));
      dispatch(setIsloggedin(true));

      if (userData.user.is_profile_completed) {
        if (userData.user.role_type === 'owner') {
          navigate('/');
        } else if (userData.user.role_type === 'professional') {
          navigate('/');
        } else {
          navigate('/');
        }
      } else if (!userData.user.is_verified) {
        navigate('/signup');
      } else if (userData.user.role_type === 'general') {
        navigate('/selectRole');
      } else if (userData.user.role_type === 'owner') {
        navigate('/registration/owner');
      } else if (userData.user.role_type === 'professional') {
        navigate('/registration/professional');
      } else {
        navigate('/');
      }
    } catch (error) {
      setErrorMessage('Invalid Credentials');
    }

    setIsLoading(false);
  };

  const handleSignInPhone = (phone, password) => {
    setIsLoading(true);
    axios
      .post(`https://api.mddentalstaffing.com/api/v1/login`, {
        mobile: phone,
        password: password,
      })
      .then((response) => {
        dispatch(setAuthToken(response.data.data.token));
        dispatch(setUser(response.data.data.user));
        localStorage.setItem('auth_token', response.data.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.data.user));
        console.log(user);

        if (user.is_verified !== true) {
          navigate('/signup');
        } else if (user.role_type === 'general') {
          navigate('/selectRole');
        } else if (user.role_type === 'owner') {
          navigate('/registration/owner');
        } else if (user.role_type === 'professional') {
          navigate('/registration/professional');
        } else {
          navigate('/');
        }
      })
      .catch((error) => {
        setErrorMessage('Invalid Credential');
      });
    setIsLoading(false);
  };


  return (
    <Row className='justify-content-between p-0 m-0'>
      <Col className='d-none d-md-grid p-0 m-0' md={2}>
        <Image
          style={{ height: '100vh' }}
          className='img-fluid'
          src={leftShoulder}
        />
      </Col>

      <Col
        style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
        className='m-0'
        md={8}
      >
        <Login
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleSignIn={handleSignIn}
          handleSignInPhone={handleSignInPhone}
          isLoading={isLoading}
        />
        <p
          className='mt-auto text-center mx-auto text-lightgrey'
          style={{ fontSize: '0.73rem' }}
        >
          If you have any questions, please contact us at:{' '}
          <a href='https://maydaydentalstaffing.com'>
            maydaydentalstaffing.com
          </a>{' '}
          <br />. Mayday Dental Staffing is a registered trademark. Copyright ©
          Mayday Dental Staffing. All rights reserved. Patent pending.
        </p>
      </Col>

      <Col className='d-none d-md-grid p-0 m-0' md={2}>
        <Image
          style={{ height: '100vh' }}
          className='img-fluid ms-auto'
          src={rightShoulder}
        />
      </Col>
    </Row>
  );
}
