import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/icons/Logo.svg";

export default function Login({
  errorMessage,
  setErrorMessage,
  handleSignIn,
  handleSignInPhone,
  isLoading,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [createType, setCreateType] = useState("email");
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Col xs={"auto"} xxl={4} className="text-center mx-auto mt-3">
      <Image className="my-3" src={logo} />
      <h2 className="mt-2 fw-semibold text-grey">Welcome!</h2>
      <div className="d-flex justify-content-center align-items-center mb-4">
        <hr className="pb-2 text-grey" style={{ width: "40px" }} />
        <h6 className="fw-regular text-grey mx-2"> Login </h6>
        <hr className="pb-2 text-grey" style={{ width: "40px" }} />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (email.length > phone.length) {
            handleSignIn(email, password);
          } else {
            handleSignInPhone(phone, password);
          }
        }}
      >
        {createType === "email" && (
          <TextField
            required
            placeholder="example@gmail.com"
            className="w-100"
            value={email}
            label="E-mail"
            error={errorMessage !== ""}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              mb: 2,
            }}
            onChange={(e) => {
              setErrorMessage("");
              setEmail(e.target.value);
            }}
          />
        )}

        {createType === "phone" && (
          <TextField
            className="w-100"
            required
            name="Phone"
            InputLabelProps={{
              shrink: true,
            }}
            value={phone}
            label="Phone"
            error={errorMessage !== ""}
            variant="outlined"
            sx={{
              mb: 2,
            }}
            inputProps={{
              minLength: 12,
              maxLength: 12,
              type: "tel",
            }}
            onChange={(e) => {
              setErrorMessage("");
              const inputValue = e.target.value;
              setphone(
                inputValue.startsWith("+1") ? inputValue : `+1${inputValue}`
              );
            }}
            onInput={(e) => {
              e.target.value = e.target.value
                .replace(/[^0-9+]/g, "")
                .slice(0, 12);
            }}
          />
        )}

        <br />
        <TextField
          required
          type={showPassword ? "text" : "password"}
          label="Password"
          variant="outlined"
          value={password}
          onChange={(e) => {
            setErrorMessage("");
            setPassword(e.target.value);
          }}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          className="w-100"
          error={errorMessage !== ""}
          sx={{
            mb: 2,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ opacity: 0.6 }}
                  onClick={handleTogglePassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errorMessage && (
          <div
            className="text-start"
            style={{ color: "#FA5A16", fontSize: "0.7rem" }}
          >
            {errorMessage}
          </div>
        )}
        {createType === "email" && (
          <div className="d-flex justify-content-between align-items-center mt-2 mb-4 w-100 mx-auto">
            <span
              style={{ cursor: "pointer", color: "#2561B0" }}
              onClick={() => {
                setCreateType("phone");
                setEmail("");
                localStorage.setItem("createType", "phone");
                setErrorMessage("");
              }}
            >
              Use phone number instead
            </span>
          </div>
        )}
        {createType === "phone" && (
          <div className="d-flex justify-content-between align-items-center mt-2 mb-4 w-100 mx-auto">
            <span
              style={{ cursor: "pointer", color: "associated" }}
              onClick={() => {
                setCreateType("email");
                setphone("");
                localStorage.setItem("createType", "email");
                setErrorMessage("");
              }}
            >
              Use email instead
            </span>
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center mb-4 w-100 mx-auto">
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Remember Me"
            sx={{ color: "#595959" }}
          />
          <Link to={"/recover-password"} className="text-decoration-none">
            <span style={{ color: "#2561B0" }}>Forgot Password?</span>
          </Link>
        </div>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{
            width: "100%",
            borderRadius: "4px",
            height: "56px",
            background: "#2561B0",
            boxShadow: "none",
            textTransform: "none",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Sign In"
          )}
        </Button>
      </form>

      <div className="mt-3 text-grey">
        Don't have an account?
        <Link to={"/signup"} className="text-decoration-none">
          <span style={{ color: "#2561B0" }}> Sign Up</span>
        </Link>
      </div>
    </Col>
  );
}
