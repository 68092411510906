import { Grid } from '@mui/material';
import React from 'react';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { Link } from 'react-router-dom';

const NavigationPanel = ({ items }) => {
  return (
    <Grid
      sx={{
        px: 3,
        borderBottom: '1px solid #D9D9D9',
        width: 'auto',
        color: '#8C8C8C',
        fontSize: '0.9rem',
      }}
    >
      {items.map((item, index) => (
        <Link
          to={item.link}
          key={index}
          style={{ textDecoration: 'none', color: 'inherit', mr: 2 }}
        >
          <span
            style={{
              color:
                window.location.pathname === item.link ? '#595959' : '#8C8C8C',
              transition: 'all 0.3s ease',
            }}
          >
            <span>
              {index !== 0 && (
                <span
                  style={{
                    marginRight: '0.5rem',
                  }}
                >
                  {' / '}
                </span>
              )}
            </span>
            {index === 0 && (item.icon === undefined || item.icon === null) && (
              <span>
                <WorkOutlineIcon sx={{ py: 0.5, my: 0.2, mr: 0.9 }} />
              </span>
            )}
            {item.icon && (
              <span
                style={{
                  marginRight: '0.5rem',
                }}
              >
                {item.icon}
              </span>
            )}
            {item.name}
          </span>
        </Link>
      ))}
    </Grid>
  );
};

export default NavigationPanel;
