import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SvgIcon from '@mui/material/SvgIcon';
import Icon from '@mui/material/Icon';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Close from '@mui/icons-material/Close';
import Cam from './Cam';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import { Col, Image, Row } from "react-bootstrap";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Scanner } from '@yudiel/react-qr-scanner';



import {
    setIscheckedIn,
    getCheckedInStatus,
    fetchIscheckedIn,
    selectIscheckedIn
} from '../../redux/slices/checkInSlice';

import { useSelector } from 'react-redux';


// const usePermission = () => {
//     const [state, setState] = useState('');

//     useEffect(() => {
//         let mounted = true;
//         let permissionStatus = null;

//         const onChange = () => {
//             if (!mounted) {
//                 return;
//             }
//             setState(() => permissionStatus?.state ?? '');
//         };

//         navigator.permissions.query(permissionDesc).then((status) => {
//             permissionStatus = status;
//             on(permissionStatus, 'change', onChange);
//             onChange();
//         }).catch(noop);

//         return () => {
//             permissionStatus && off(permissionStatus, 'change', onChange);
//             mounted = false;
//             permissionStatus = null;
//         };
//     }, [permissionDesc]);

//     return state;
// };

export default function ProCheckInDialog({ open, handleClose, verified, unverified }) {

    const theme = useTheme();
    const dispatch = useDispatch();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [codes, setCodes] = useState(["", "", "", "", "", ""]);
    const [data, setData] = useState('No result');
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [openScanner, setOpenScanner] = useState(false);
    const [qrCode, setQrCode] = React.useState(null);
    const [code, setCode] = React.useState(null);
    const [value, setValue] = React.useState('1');
    const [progress, setProgress] = React.useState(100);
    const [timer, setTimer] = useState(10);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const ischeckedIn = useSelector(selectIscheckedIn);

    useEffect(() => {
        if (open && isVerified) {
            const timeoutId = setTimeout(() => {
                // setClosing(true);
                setTimeout(() => {
                    handleClose();
                }, 300);
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [open, handleClose, isVerified]);

    useEffect(() => {
        if (!codes.includes("")) {
            const verificationCode = codes.join("");
            verifyQrCode({ 'code': verificationCode, 'is_scanner': false });
        }
    }, [codes])


    useEffect(() => {

        // usePermission();
        // getVideoStream();
        // if (navigator.getUserMedia) {
        //     navigator.getUserMedia(
        //         {
        //             video: true
        //         },
        //         function (localMediaStream) { },
        //         function (err) {
        //             alert('The following error occurred when trying to access the camera: ' + err);
        //         }
        //     );
        // } else {
        //     alert('Sorry, browser does not support camera access');
        // }


    }, [])

    const handleArrowKey = (index, direction) => {
        const newIndex = direction === "left" ? index - 1 : index + 1;
        if (newIndex >= 0 && newIndex < 6) {
            document.getElementById(`code-input-${newIndex}`).focus();
        }
    };

    const handleKeyPress = (index, e) => {
        if (e.key === "ArrowLeft") {
            handleArrowKey(index, "left");
        } else if (e.key === "ArrowRight") {
            handleArrowKey(index, "right");
        }
    };

    const handleChange = (index, value) => {
        const newCodes = [...codes];
        newCodes[index] = value;

        if (value && index < 5) {
            document.getElementById(`code-input-${index + 1}`).focus();
        } else if (!value && index > 0) {
            document.getElementById(`code-input-${index - 1}`).focus();
        }
        setCodes(newCodes);
    };

    const verifyQrCode = async (payload) => {
        setIsLoading(true);
        setOpenScanner(false);
        axios.post(`https://api.mddentalstaffing.com/api/v1/verify-qr-code`, payload, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            }
        }).then((response) => {
            // var qr = response.data.data.verify;
            var qr = response.data.data;
            setIsVerified(response.data.data.verify);
            setIsLoading(false);
            dispatch(setIscheckedIn(true));
            verified();
        }).catch((e) => {
            setIsLoading(false);
            unverified();
            codes.map((code, index) => {
                const newList = codes;
                codes[index] = "";
                setCodes(newList);
            });
            console.log(e.message);
        });
    };

    const handleNext = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setIsLoading(true);
        const verificationCode = codes.join("");
        setIsLoading(false);
    };

    const onScan = (res) => {
        const payload = JSON.parse(res[0].rawValue);
        payload['is_scanner'] = true;
        verifyQrCode(payload);
    };

    const getVideoStream = async () => {
        try {
            const permissionStatus = await navigator.permissions.query({
                name: 'camera'
            });

            if (permissionStatus.state === 'denied') {
                console.error('Camera permission has been denied');
                return;
            }
        } catch (error) {
            console.error('Error checking camera permissions:', error);
            return;
        }

        // let stream: MediaStream | null = null;

        // try {
        //     stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // } catch (error) {
        //     console.error('Error getting video stream:', error);
        //     return;
        // }

        // if (!stream || stream.getVideoTracks().length === 0) {
        //     console.error('Could not get a valid video track.');
        //     return;
        // }
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // fullWidth='sm'
                // maxWidth='sm'
                // fullScreen={fullScreen}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "30%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",

                    }}

                >
                    <DialogTitle id="alert-dialog-title">
                        {"Check In"}
                    </DialogTitle>
                </div>
                <DialogContent >
                    <Box sx={{ typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange}
                                    aria-label="lab API tabs example" centered={true}
                                    variant="fullWidth"
                                >
                                    <Tab label="QR Code" value="1" />
                                    <Tab label="Verification Code" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: "260px",
                                                    width: '250px',
                                                }}
                                            >
                                                {openScanner ? (
                                                    <>
                                                        <Scanner
                                                            onScan={onScan}
                                                            paused={isLoading}
                                                            style={{
                                                                height: "250px",
                                                                width: '250px',
                                                            }}
                                                            my={1}
                                                        />
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <Button
                                                                variant='contained'
                                                                color='primary'
                                                                sx={{
                                                                    mt: '3px',
                                                                    mb: '6px',
                                                                    borderRadius: '4px',
                                                                    boxShadow: 'none',
                                                                    background: '#2561B0',
                                                                    textTransform: 'none',
                                                                }}
                                                                onClick={() => {
                                                                    setOpenScanner(false);
                                                                }}
                                                            >
                                                                <Close />Close Scanner
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <Box
                                                                height={200}
                                                                width={200}
                                                                my={2}
                                                                display="flex"
                                                                alignItems="center"
                                                                gap={4}
                                                                color="primary"
                                                                // p={2}
                                                                sx={{
                                                                    border: '2px solid #a1afbd', borderRadius: 1,
                                                                }}
                                                            >
                                                                <QrCodeScannerIcon color="#a1afbd" sx={{ fontSize: 200 }} />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <Button
                                                                variant='contained'
                                                                color='primary'
                                                                sx={{
                                                                    borderRadius: '4px',
                                                                    boxShadow: 'none',
                                                                    background: '#2561B0',
                                                                    textTransform: 'none',
                                                                }}
                                                                onClick={() => {
                                                                    setOpenScanner(true);
                                                                }}
                                                            >
                                                                <QrCodeScannerIcon />Scan QR Code
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                )}
                                            </div>
                                        </Grid>
                                        {isLoading ? (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <CircularProgress />
                                            </Grid>
                                        ) : (
                                            <>
                                                {isVerified ? (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <DoneIcon fontSize='large' style={{ color: '#4CAF50' }} />
                                                    </Grid>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2">
                                <div
                                    style={{
                                        // width: 250,
                                        height: "250px",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Col md={8} className="text-center  mt-3" >
                                        <Row className="text-center justify-content-center mb-3">
                                            {codes.map((code, index) => (
                                                <Col className="mx-1 p-0 text-center" key={index}>
                                                    <TextField
                                                        id={`code-input-${index}`}
                                                        className="form-control text-end"
                                                        placeholder="0"
                                                        error={errorMessage != ""}
                                                        value={code}
                                                        disabled={isLoading}
                                                        onChange={(e) => {
                                                            setErrorMessage("");
                                                            const value = e.target.value;
                                                            if (/^[0-9]?$/.test(value)) {
                                                                handleChange(index, value);
                                                            } else if (value === "") {
                                                                handleChange(index, "");
                                                            }
                                                        }}
                                                        inputProps={{
                                                            style: { textAlign: "center" },
                                                            maxLength: 1,
                                                            type: "tel",
                                                        }}
                                                        onKeyDown={(e) => handleKeyPress(index, e)}
                                                    />
                                                </Col>
                                            ))}
                                            {errorMessage && (
                                                <div
                                                    className="text-start mt-2"
                                                    style={{ color: "#FA5A16", fontSize: "0.7rem" }}
                                                >
                                                    {errorMessage}
                                                </div>
                                            )}
                                        </Row>
                                        {isLoading ? (
                                            <Row className="text-center justify-content-center mb-3">
                                                <Col className="mx-1 p-0 text-center">
                                                    <CircularProgress />
                                                </Col>
                                            </Row>
                                        ) : (
                                            <>
                                                {isVerified ? (
                                                    <Row>
                                                        <Col xs={6} className="text-center mx-auto mt-3" >
                                                            <DoneIcon fontSize='large' style={{ color: '#4CAF50' }} />
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </Col>
                                </div>
                            </TabPanel>
                        </TabContext>
                    </Box>
                    <hr />
                    <Box sx={{ flexGrow: 1 }}
                        style={{
                            // width: 250,
                            // height: 250,
                            display: "flex",
                            justifyContent: "center",
                        }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <QrCodeScannerIcon sx={{ fontSize: 50 }} />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '12px'
                                    }}
                                >
                                    <p> Please scan qr code to check in for this posting. If unable to do so use verification code</p>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    ...
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}