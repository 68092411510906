import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import authReducer from "./slices/authSlice";
import notificationReducer from "./slices/notificationSlice";
import checkInReducer from "./slices/checkInSlice";
import loadingReducer from "./reducer/loadingReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    loading: loadingReducer,
    notifications: notificationReducer,
    ischeckedInState: checkInReducer,
  }
});
